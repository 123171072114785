import React from 'react';
import styled from 'styled-components/native';

const Container = styled.View`
  width: 100%;
  margin-vertical: 8px;
`;

const TitleContainer = styled.View`
  flex-direction: row;
`;

const LabelText = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;
// font-family: "SF Pro Display";

const RequiredText = styled.Text`
  color: ${(props) => props.theme.colors.error.red};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
`;
// font-family: "SF Pro Display";

const InputContainer = styled.View`
  height: 56px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
  padding-horizontal: 16px;
  padding-bottom: 7px;
`;

const Input = styled.TextInput.attrs({
  multiline: false,
  outlineStyle: 'none',
  // autoComplete: "username",
  // keyboardType: "email-address",
  // textContentType: "username",
  // autoCapitalize: "none",
  // returnKeyType: "next",
  // autoFocus: true,
})`
  flex: 1;
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  padding: 3px;
`;
// font-family: "SF Pro Display";

export const FormFieldText = ({
  label,
  value,
  required = false,
  placeholder,
  keyboardType,
  autoCapitalize,
  onChangeText,
}) => {
  return (
    <Container>
      <TitleContainer>
        <LabelText>{label}</LabelText>
        {required && <RequiredText>(Required)</RequiredText>}
      </TitleContainer>
      <InputContainer>
        <Input
          value={value}
          placeholder={placeholder}
          onChangeText={onChangeText}
          keyboardType={keyboardType}
          autoCapitalize={autoCapitalize}
          editable={typeof onChangeText === 'function'}
        />
      </InputContainer>
    </Container>
  );
};
