import React, { useState } from 'react';
import {
  Background,
  BarHeaderSmall,
  BarSwitcher,
  VerticalScroll,
} from '../../../components/ui';

import { ClubDetails } from '../components/club-detais.component';
import { ClubModules } from '../components/club-modules.component';
import { ClubUsers } from '../components/club-users.component';
import { ClubPlayers } from '../components/club-players.component';

export const ClubDetailScreen = ({ navigation, route }) => {
  const { club } = route.params;
  const tabItems = ['Details', 'Modules', 'Users', 'Players'];
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <Background>
      <BarHeaderSmall
        title={'Club #' + club.id}
        leftButtonTitle="Back"
        onLeftPress={() => navigation.goBack()}
      />
      <BarSwitcher
        items={tabItems}
        tab={currentTab}
        onSelected={(item) => {
          setCurrentTab(tabItems.findIndex((i) => i === item));
        }}
      />
      <VerticalScroll>
        {currentTab === 0 ? (
          <ClubDetails navigation={navigation} club={club} />
        ) : currentTab === 1 ? (
          <ClubModules navigation={navigation} club={club} />
        ) : currentTab === 2 ? (
          <ClubUsers navigation={navigation} club={club} />
        ) : (
          currentTab === 3 && (
            <ClubPlayers navigation={navigation} club={club} />
          )
        )}
      </VerticalScroll>
    </Background>
  );
};
