import React from 'react';
import styled from 'styled-components/native';

const Container = styled.View`
  align-items: center;
`;

const CenteredView = styled.View`
  min-width: 320px;
  max-width: 480px;
  width: 100%;
`;

export const Centered = ({ children }) => {
  return (
    <Container>
      <CenteredView>{children}</CenteredView>
    </Container>
  );
};
