import camelize from 'camelize';
import { format } from 'date-fns';
import { host } from '../../utils/env';

const formattedDate = (isodate) => {
  const date = new Date(isodate);
  if (date.getFullYear() === new Date().getFullYear()) {
    return format(date, 'dd MMM');
  } else {
    return format(date, 'dd MMM yyyy');
  }
};

export const getReportFiles = async ({ token, report }) => {
  return fetch(
    `${host}/v1/clubs/${report.club.id}/reports/${report.id}/report_files`,
    {
      headers: token,
    },
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

function dataURItoFile(dataURI, file_name, file_type) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  var blob = new Blob([ia], { type: mimeString });

  return new File([blob], file_name, {
    type: file_type,
  });
}

export const createReportFile = async ({ token, report, file }) => {
  var formdata = new FormData();
  formdata.append('report_file[description]', file.description);
  if (file.file.uri.startsWith('file')) {
    formdata.append('report_file[file]', {
      name: file.file.name,
      type: file.file.type,
      uri: file.file.uri,
    });
  }
  if (file.file.uri.startsWith('data')) {
    formdata.append(
      'report_file[file]',
      dataURItoFile(file.file.uri, file.file.name, file.file.mimeType),
    );
  }
  return fetch(
    `${host}/v1/clubs/${report.club.id}/reports/${report.id}/report_files`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        ...token,
      },
      body: formdata,
    },
  )
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const updateReportFile = async ({ token, report, report_file }) => {
  var formdata = new FormData();
  formdata.append('report_file[name]', report_file.name);
  formdata.append('report_file[description]', report_file.description);
  if (report_file.file.uri.startsWith('file')) {
    formdata.append('report_file[file]', {
      name: report_file.file.name,
      type: report_file.file.type,
      uri: report_file.file.uri,
    });
  }
  if (report_file.file.uri.startsWith('data')) {
    formdata.append(
      'report_file[file]',
      dataURItoFile(
        report_file.file.uri,
        report_file.file.name,
        report_file.file.type,
      ),
    );
  }
  return fetch(
    `${host}/v1/clubs/${report.club.id}/reports/${report.id}/report_files/${report_file.id}`,
    {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        ...token,
      },
      body: formdata,
    },
  )
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const reportFileTransform = (result = {}) => {
  result.createdAtShort = formattedDate(result.created_at);
  result.updatedAtShort = formattedDate(result.updated_at);
  return camelize(result);
};

export const reportFilesTransform = (results = []) => {
  return results.map((result) => reportFileTransform(result));
};
