import React, { createContext, useContext, useState } from 'react';
import {
  getClubUsers,
  createClubUser,
  updateCluUser,
  removeClubUser,
  clubUserTransform,
  clubUsersTransform,
} from './club-users.service';
import { AuthenticationContext } from '../authentication/authentication.context';

export const ClubUsersContext = createContext();

export const ClubUsersContextProvider = ({ children }) => {
  const { token } = useContext(AuthenticationContext);
  const [clubUsers, setClubUsers] = useState([]);
  const clubUserTypes = ['standard', 'owner', 'simpler'];
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onRetrieve = async ({ club }) => {
    setIsLoading(true);
    setClubUsers([]);
    await getClubUsers({ token, club })
      .then(clubUsersTransform)
      .then((results) => {
        setIsLoading(false);
        setClubUsers(results);
        setError(null);
      })
      .catch((err) => {
        setIsLoading(false);
        setClubUsers([]);
        setError(err);
      });
  };

  const onCreate = ({ club, clubUser }) => {
    setIsLoading(true);
    createClubUser({ token, club, clubUser })
      .then(clubUserTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          setClubUsers([response, ...clubUsers]);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onUpdate = ({ club, clubUser }) => {
    setIsLoading(true);
    updateCluUser({ token, club, clubUser })
      .then(clubUserTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          const index = clubUsers.findIndex(
            (record) => record.id === response.id,
          );
          clubUsers[index] = response;
          setClubUsers(clubUsers);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onDelete = ({ club, clubUser }) => {
    setIsLoading(true);
    removeClubUser({ token, club, clubUser })
      .then(clubUserTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          const filtered = clubUsers.filter((i) => i.id !== clubUser.id);
          setClubUsers(filtered);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  return (
    <ClubUsersContext.Provider
      value={{
        clubUsers,
        clubUserTypes,
        isLoading,
        error,
        onRetrieve,
        onCreate,
        onUpdate,
        onDelete,
      }}
    >
      {children}
    </ClubUsersContext.Provider>
  );
};
