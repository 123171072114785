import React, { useContext, useEffect, useMemo } from 'react';
import {
  BarHeaderSmall,
  Centered,
  FormTableSelect,
  VerticalScroll,
} from '../../../components/ui/';
import styled from 'styled-components/native';

import { ProfileContext } from '../../../services/profile/profile.context';
import { TeamsContext } from '../../../services/teams/teams.context';

const Background = styled.View``;
const TeamContainer = styled.View`
  height: 180px;
`;
const TeamList = styled(FormTableSelect)`
  margin-top: 0px;
  padding: 1px;
`;

const generateTeamValues = (_teams) => {
  let tempTeams = _teams.map((team) => ({
    key: team.id,
    value: team.name,
  }));
  if (tempTeams.length > 1) {
    tempTeams = [
      {
        key: null,
        value: 'All my teams',
      },
      ...tempTeams,
    ];
  }
  return tempTeams;
};

export const ChangeTeamScreen = ({ navigation }) => {
  const { currentTeam, setCurrentTeam } = useContext(ProfileContext);
  const { teams, onRetrieve } = useContext(TeamsContext);
  const teamValues = useMemo(() => generateTeamValues(teams), [teams]);
  const initialKeys = useMemo(() => {
    return currentTeam?.id || null;
  }, [currentTeam]);

  useEffect(() => {
    onRetrieve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _setTeam = (selected) => {
    if (selected.length > 0) {
      const index = teams.findIndex((team) => team.id === selected[0]);
      let selectedTeam = teams[index];
      setCurrentTeam(selectedTeam);
      navigation.goBack();
    }
  };

  return (
    <Background>
      <BarHeaderSmall
        title="Pick a team"
        leftButtonTitle="Cancel"
        onLeftPress={() => navigation.goBack()}
      />
      <TeamContainer>
        <VerticalScroll>
          <Centered>
            <TeamList
              label={''}
              values={teamValues}
              initialKeys={[initialKeys]}
              multiselect={false}
              onSelected={_setTeam}
            />
          </Centered>
        </VerticalScroll>
      </TeamContainer>
    </Background>
  );
};
