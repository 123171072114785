import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { AppTabNavigator } from './app-tab.navigation';
import { ProfileContextProvider } from '../../services/profile/profile.context';
import { ReportsContextProvider } from '../../services/reports/reports.context';
import { DocumentsContextProvider } from '../../services/documents/documents.context';
import { PlayersContextProvider } from '../../services/players/players.context';
import { TeamsContextProvider } from '../../services/teams/teams.context';
import { ChangeClubScreen } from '../../features/clubs/screens/change-club.screen';
import { ChangeTeamScreen } from '../../features/teams/screens/change-team.screen';

const AppStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    presentation: 'modal',
    headerShown: false,
  };
};

export const AppNavigator = () => {
  return (
    <ProfileContextProvider>
      <ReportsContextProvider>
        <DocumentsContextProvider>
          <PlayersContextProvider>
            <TeamsContextProvider>
              <AppStack.Navigator screenOptions={createScreenOptions}>
                <AppStack.Screen
                  name="HomeTabNavigation"
                  component={AppTabNavigator}
                  options={{ headerShown: false }}
                />
                <AppStack.Screen
                  name="ChangeClubScreen"
                  component={ChangeClubScreen}
                />
                <AppStack.Screen
                  name="ChangeTeamScreen"
                  component={ChangeTeamScreen}
                />
              </AppStack.Navigator>
            </TeamsContextProvider>
          </PlayersContextProvider>
        </DocumentsContextProvider>
      </ReportsContextProvider>
    </ProfileContextProvider>
  );
};
