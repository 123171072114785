import React, { createContext, useContext, useState } from 'react';
import {
  getClubPlayers,
  createClubPlayer,
  updateClubPlayer,
  removeClubPlayer,
  clubPlayerTransform,
  clubPlayersTransform,
} from './club-players.service';
import { AuthenticationContext } from '../authentication/authentication.context';

export const ClubPlayersContext = createContext();

export const ClubPlayersContextProvider = ({ children }) => {
  const { token } = useContext(AuthenticationContext);
  const [clubPlayers, setClubPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onRetrieve = async (club) => {
    setIsLoading(true);
    setClubPlayers([]);
    await getClubPlayers({ token, club })
      .then(clubPlayersTransform)
      .then((results) => {
        setIsLoading(false);
        setClubPlayers(results);
        setError(null);
      })
      .catch((err) => {
        setIsLoading(false);
        setClubPlayers([]);
        setError(err);
      });
  };

  const onCreate = (club, clubPlayer) => {
    setIsLoading(true);
    createClubPlayer({ token, club, clubPlayer })
      .then(clubPlayerTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          setClubPlayers([response, ...clubPlayers]);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onUpdate = (club, clubPlayer) => {
    setIsLoading(true);
    updateClubPlayer({ token, club, clubPlayer })
      .then(clubPlayerTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          const index = clubPlayers.findIndex(
            (record) => record.id === response.id,
          );
          clubPlayers[index] = response;
          setClubPlayers(clubPlayers);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onDelete = (club, clubPlayer) => {
    setIsLoading(true);
    removeClubPlayer({ token, club, clubPlayer })
      .then(clubPlayerTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          const filtered = clubPlayers.filter((i) => i.id !== clubPlayer.id);
          setClubPlayers(filtered);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  return (
    <ClubPlayersContext.Provider
      value={{
        clubPlayers,
        isLoading,
        error,
        onRetrieve,
        onCreate,
        onUpdate,
        onDelete,
      }}
    >
      {children}
    </ClubPlayersContext.Provider>
  );
};
