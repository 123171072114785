import React, { useContext, useEffect, useState } from 'react';
import {
  Background,
  BarHeaderSmall,
  BarSwitcher,
  Centered,
} from '../../../components/ui';
import { ReportDetails } from '../components/report-details.component';
import { ReportNotes } from '../components/report-notes.component';
import { ReportFiles } from '../components/report-files.component';

import { ProfileContext } from '../../../services/profile/profile.context';
import { ReportNotesContextProvider } from '../../../services/report-notes/report-notes.context';
import { ReportFilesContextProvider } from '../../../services/report-files/report-files.context';

export const ReportDetailScreen = ({ navigation, route }) => {
  const { profile, currentClub } = useContext(ProfileContext);
  const { report } = route.params;
  const [tabItems, setIabItems] = useState(['Details', 'Notes', 'Files']);
  const [currentTab, setCurrentTab] = useState(0);
  const [showNotes, setShowNotes] = useState(false);

  useEffect(() => {
    if (currentClub) {
      const clubUser = profile.clubUsers.find(
        (u) => (u.club_id = currentClub.id),
      );
      if (clubUser) {
        if (clubUser.clubUserType === 'simpler') {
          setShowNotes(true);
        } else {
          const role = clubUser.clubRole?.name;
          if (role === 'Trainer') {
            setShowNotes(true);
          }
          if (role === 'Medical Manager') {
            setShowNotes(true);
          }
        }
      }
    }
  }, [currentClub, profile]);

  useEffect(() => {
    if (showNotes) {
      setIabItems(['Details', 'Notes', 'Files']);
    } else {
      setIabItems(['Details']);
    }
  }, [showNotes]);

  return (
    <ReportNotesContextProvider report={report}>
      <ReportFilesContextProvider report={report}>
        <Background>
          <BarHeaderSmall
            title={'Medical Report #' + report.id}
            leftButtonTitle="Back"
            onLeftPress={() => navigation.goBack()}
          />
          <Centered>
            <BarSwitcher
              items={tabItems}
              tab={currentTab}
              onSelected={(item) => {
                setCurrentTab(tabItems.findIndex((i) => i === item));
              }}
            />
          </Centered>

          {currentTab === 0 && (
            <ReportDetails report={report} navigation={navigation} />
          )}
          {currentTab === 1 && <ReportNotes report={report} />}
          {currentTab === 2 && <ReportFiles report={report} />}
        </Background>
      </ReportFilesContextProvider>
    </ReportNotesContextProvider>
  );
};
