import React, { useContext, useEffect, useState, useRef } from 'react';
import { ActivityIndicator, Colors } from 'react-native-paper';
import * as Linking from 'expo-linking';

import {
  AccountContainer,
  AuthFields,
  AuthInputBorder,
  AuthInput,
  ErrorContainer,
} from '../components/account.styles';
import { Spacer } from '../../../components/spacer/spacer.component';
import { Text } from '../../../components/typography/text.component';
import { Button } from '../components/button.component';
import { Background } from '../components/background.component';

import { AuthenticationContext } from '../../../services/authentication/authentication.context';

export const CreatePasswordScreen = ({ navigation }) => {
  const [token, setToken] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { onResetPassword, isLoading, error } = useContext(
    AuthenticationContext,
  );
  const confirmPasswordField = useRef(null);

  const isSubmittable = () => {
    return (
      password !== '' && confirmPassword !== '' && password === confirmPassword
    );
  };

  useEffect(() => {
    Linking.getInitialURL().then((url) => {
      const myURL = new URL(url);
      const t = myURL.searchParams.get('reset_password_token');
      setToken(t);
    });
  }, []);

  return (
    <Background>
      <AccountContainer>
        <Spacer size="heading" position="bottom">
          <Text variant="accountHeading">Create New Password</Text>
        </Spacer>
        <AuthFields>
          <AuthInputBorder>
            <AuthInput
              value={password}
              placeholder="Password"
              autoComplete="password"
              textContentType="password"
              autoCapitalize="none"
              secureTextEntry={true}
              returnKeyType="next"
              onChangeText={setPassword}
              onSubmitEditing={() => {
                confirmPasswordField.current.focus();
              }}
            />
            <AuthInput
              ref={confirmPasswordField}
              value={confirmPassword}
              placeholder="Confirm Password"
              autoComplete="password"
              textContentType="password"
              autoCapitalize="none"
              secureTextEntry={true}
              returnKeyType="go"
              onChangeText={setConfirmPassword}
              onSubmitEditing={() => onResetPassword(password)}
            />
          </AuthInputBorder>
        </AuthFields>
        {!error && (
          <ErrorContainer size="large">
            <Text variant="error">{error}</Text>
          </ErrorContainer>
        )}
        <Spacer size="large">
          {!isLoading ? (
            <Button
              title={'Let Me In'}
              isDisabled={!isSubmittable()}
              onPress={() => onResetPassword(password, confirmPassword, token)}
            />
          ) : (
            <ActivityIndicator animating={true} color={Colors.blue300} />
          )}
        </Spacer>
      </AccountContainer>
    </Background>
  );
};
