import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';

import styled from 'styled-components/native';
import { Text } from '../../../components/typography/text.component';

const BlackBackground = styled.View`
  flex: 1;
  background-color: ${(props) => props.theme.colors.pure.black};
`;

const SafeArea = styled(SafeAreaView).attrs({
  edges: ['top', 'right', 'left'],
})`
  flex: 1;
`;

const BlueBackground = styled.View`
  flex: 1;
  background-color: ${(props) => props.theme.colors.simple.blue};
`;

const Content = styled.View`
  flex: 1;
  align-items: center;
`;

const Header = styled.View`
  align-items: center;
  width: 100%;
  padding: ${(props) => props.theme.space[5]};
`;

const IconImage = styled.Image`
  width: 30px;
  height: 40px;
`;

export const Footer = styled.View`
  position: absolute;
  bottom: ${(props) => props.theme.space[5]};
  align-items: center;
  width: 100%;
`;

export const Background = ({
  children,
  includeHeader = true,
  includeFooter = true,
}) => {
  return (
    <BlackBackground>
      <SafeArea>
        <BlueBackground>
          {includeHeader && (
            <Header>
              <IconImage
                source={require('../../../../assets/logos/monogram_white.png')}
              />
            </Header>
          )}
          <Content>{children}</Content>
          {includeHeader && (
            <Footer>
              <Text variant="trademark">
                © 2022 Simpler Software Pty Ltd. All rights reserved
              </Text>
            </Footer>
          )}
        </BlueBackground>
      </SafeArea>
    </BlackBackground>
  );
};
