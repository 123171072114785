import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  getDocuments,
  createDocument,
  updateDocument,
  documentsTransform,
  documentTransform,
} from './documents.service';
import { AuthenticationContext } from '../authentication/authentication.context';
import { ProfileContext } from '../profile/profile.context';

export const DocumentsContext = createContext();

export const DocumentsContextProvider = ({ children }) => {
  const { user, token } = useContext(AuthenticationContext);
  const { currentClub } = useContext(ProfileContext);

  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onRetrieve = async () => {
    if (currentClub) {
      setIsLoading(true);
      setDocuments([]);
      await getDocuments({ token, club_id: currentClub.id })
        .then(documentsTransform)
        .then((results) => {
          setIsLoading(false);
          setDocuments(results);
          setError(null);
        })
        .catch((err) => {
          setIsLoading(false);
          setDocuments([]);
          setError(err);
        });
    }
  };

  const onCreate = (document) => {
    if (currentClub) {
      setIsLoading(true);
      createDocument({ token, club_id: currentClub.id, document })
        .then(documentTransform)
        .then((response) => {
          if (response.status === 'error') {
            setIsLoading(false);
            setError(response.errors.full_messages.toString());
          } else {
            setDocuments([response, ...documents]);
            setIsLoading(false);
            setError('');
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e.toString());
        });
    }
  };

  const onUpdate = (document) => {
    if (currentClub) {
      setIsLoading(true);
      updateDocument({ token, club_id: currentClub.id, document })
        .then(documentTransform)
        .then((response) => {
          if (response.status === 'error') {
            setIsLoading(false);
            setError(response.errors.full_messages.toString());
          } else {
            const index = documents.findIndex(
              (record) => record.id === response.id,
            );
            documents[index] = response;
            setDocuments(documents);
            setIsLoading(false);
            setError('');
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e.toString());
        });
    }
  };

  useEffect(() => {
    if (user) {
      onRetrieve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentClub]);

  return (
    <DocumentsContext.Provider
      value={{
        documents,
        isLoading,
        error,
        onRetrieve,
        onCreate,
        onUpdate,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};
