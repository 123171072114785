import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import {
  Background,
  BarHeaderSmall,
  ButtonBlue,
  ButtonWhite,
  Centered,
  CardInformation,
} from '../../../components/ui/';
import * as DocumentPicker from 'expo-document-picker';

import { ClubPlayerListsContext } from '../../../services/club-player-lists/club-player-lists.context';

const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const ClubPlayerImportScreen = ({ route, navigation }) => {
  const { onCreate } = useContext(ClubPlayerListsContext);

  const [club, setClub] = useState(null);
  const [file, setFile] = useState({});
  const [filename, setFilename] = useState('');

  const pickFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result?.type === 'success') {
      setFile(result);
      setFilename(result.name);
    }
  };

  const canSave = () => {
    return file !== {};
  };

  useEffect(() => {
    if (route.params?.club) {
      setClub(route.params?.club);
    }
  }, [route.params?.club]);

  return (
    <Background>
      <BarHeaderSmall
        title={'Update Player List'}
        leftButtonTitle="Back"
        onLeftPress={() => {
          if (canSave()) {
          }
          navigation.goBack();
        }}
      />
      <Centered>
        {club?.id && (
          <CardInformationRow>
            <CardInformation title="Club">{club?.name}</CardInformation>
            <CardInformation title="Filename">{filename}</CardInformation>
          </CardInformationRow>
        )}
        <ButtonWhite label="Pick a file" onPress={() => pickFile()} />
        <ButtonBlue
          label={'Import'}
          isDisabled={!canSave()}
          onPress={() => {
            onCreate(club, file);
            navigation.goBack();
          }}
        />
      </Centered>
    </Background>
  );
};
