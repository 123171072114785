import React, { createContext, useContext, useState } from 'react';
import {
  createClubPlayerList,
  clubPlayerListTransform,
} from './club-player-lists.service';
import { AuthenticationContext } from '../authentication/authentication.context';

export const ClubPlayerListsContext = createContext();

export const ClubPlayerListsContextProvider = ({ children }) => {
  const { token } = useContext(AuthenticationContext);
  const [clubPlayerLists, setClubPlayerLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onCreate = (club, file) => {
    setIsLoading(true);
    createClubPlayerList({ token, club_id: club.id, file: file })
      .then(clubPlayerListTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          setClubPlayerLists([response, ...setClubPlayerLists]);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  return (
    <ClubPlayerListsContext.Provider
      value={{
        clubPlayerLists,
        isLoading,
        error,
        onCreate,
      }}
    >
      {children}
    </ClubPlayerListsContext.Provider>
  );
};
