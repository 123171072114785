import React, { useContext, useState } from 'react';
import { Centered, ButtonBlue, FormTableSelect } from '../../../components/ui';

import { AppModulesContext } from '../../../services/app-modules/app-modules.context';

export const ClubModules = ({ club, navigation }) => {
  const { appModules } = useContext(AppModulesContext);
  const [selectedItems, setSelectedItems] = useState([]);

  return (
    <Centered>
      <FormTableSelect
        label={'Active modules - ' + club.name}
        values={appModules.map((item) => ({
          key: item.id,
          value: item.name,
        }))}
        initialKeys={selectedItems}
        multiselect={true}
        onSelected={(selected) => {
          setSelectedItems(selected);
        }}
      />
      <ButtonBlue
        label="Update"
        onPress={() => {
          //TODO: - Save selected modules
        }}
      />
    </Centered>
  );
};
