import React, { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';

const isAndroid = Platform.OS === 'android';

const Container = styled.View`
  width: 100%;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  margin-vertical: 8px;
`;

const HeaderText = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;
// font-family: "SF Pro Display";

const RequiredText = styled.Text`
  color: ${(props) => props.theme.colors.error.red};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
`;
// font-family: "SF Pro Display";

const RowSelection = styled.View`
  margin-top: 2px;
  margin-bottom: 5px;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

const RowContainer = styled.View`
  width: 100%;
  flex-direction: row;
  padding: 16px;
  align-items: center;
`;

const LabelText = styled.Text`
  flex: 1;
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  min-width: 64px;
`;
// font-family: "SF Pro Display";

const ValueText = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  color: ${(props) => props.theme.colors.selectedGrey};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  max-width: 60%;
  padding-horizontal: 16px;
`;
// font-family: "SF Pro Display";

const Extra = styled.Text`
  color: ${(props) => props.theme.colors.selectedGrey};
`;

// import Forward from "../../../assets/chevron_forward.svg";
// const Icon = styled(Forward).attrs({ width: 19, height: 19 })``;
const Icon = styled.View``;

const Switch = styled.Switch``;

export const FormFieldToggle = ({
  label,
  header,
  required,
  initialValue,
  onSelected,
}) => {
  const [value, setValue] = useState(false);
  const valueText = (v) => {
    return v ? 'Yes' : 'No';
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Container>
      {!!header && (
        <HeaderContainer>
          <HeaderText>{header}</HeaderText>
          {required && <RequiredText>(Required)</RequiredText>}
        </HeaderContainer>
      )}
      <RowSelection>
        <RowContainer>
          <LabelText>{label}</LabelText>
          <ValueText>{valueText(value)}</ValueText>
          <Switch
            value={value}
            onValueChange={(v) => {
              setValue(v);
              onSelected(v);
            }}
          />
        </RowContainer>
      </RowSelection>
    </Container>
  );
};
