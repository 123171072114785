import React, { useContext, useState, useMemo } from 'react';
import { DataTable } from 'react-native-paper';

import { DocumentsContext } from '../../../services/documents/documents.context';
import { ProfileContext } from '../../../services/profile/profile.context';
import { openLink } from '../../../utils/links';

import styled from 'styled-components/native';

const Container = styled.View``;
const DataTableView = styled(DataTable)`
  background-color: white;
`;
const DataTableTitleCenter = styled(DataTable.Title).attrs({
  justifyContent: 'center',
})``;
const DataTableCellCenter = styled(DataTable.Cell).attrs({
  justifyContent: 'center',
})``;

const optionsPerPage = [2, 3, 4];

export const DocumentListRegular = ({ navigation }) => {
  const { documents } = useContext(DocumentsContext);
  const { profile, currentClub } = useContext(ProfileContext);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(optionsPerPage[0]);
  const canAddDocument = useMemo(() => {
    const clubUser = profile.clubUsers.find(
      (u) => (u.club_id = currentClub.id),
    );
    const role = clubUser.clubRole?.name;
    return role !== 'Trainer';
  }, [profile, currentClub]);

  return (
    <Container>
      <DataTableView>
        <DataTable.Header>
          <DataTable.Title>Title</DataTable.Title>
          <DataTable.Title>Description</DataTable.Title>
          <DataTable.Title>File Name</DataTable.Title>
          <DataTableTitleCenter>Date Updated</DataTableTitleCenter>
        </DataTable.Header>
        {documents.map((document) => (
          <DataTable.Row
            key={document.id}
            onPress={() => {
              if (canAddDocument) {
                navigation.navigate('DocumentDetailScreen', { document });
              } else {
                openLink(document.file.originalUrl);
              }
            }}
          >
            <DataTable.Cell>{document.name}</DataTable.Cell>
            <DataTable.Cell>{document.description}</DataTable.Cell>
            <DataTable.Cell>{document.file.filename}</DataTable.Cell>
            <DataTableCellCenter>{document.updatedAtShort}</DataTableCellCenter>
          </DataTable.Row>
        ))}
      </DataTableView>
      <DataTable.Pagination
        page={page}
        numberOfPages={1}
        onPageChange={(p) => setPage(p)}
        label="1 of 1"
        optionsPerPage={optionsPerPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        showFastPagination
        optionsLabel={'Rows per page'}
      />
    </Container>
  );
};
