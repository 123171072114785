import React, { useContext } from 'react';
import styled from 'styled-components/native';
import { CardMessage } from '../../../components/ui/';
import * as Linking from 'expo-linking';

import { DocumentsContext } from '../../../services/documents/documents.context';
import { openLink } from '../../../utils/links';

const DocumentsList = styled.FlatList``;
const PressRow = styled.Pressable``;
const RefreshControl = styled.RefreshControl``;

export const DocumentListCompact = ({ navigation }) => {
  const { isLoading, documents, onRetrieve } = useContext(DocumentsContext);

  return (
    <DocumentsList
      data={documents}
      renderItem={({ item }) => (
        <PressRow
          onPress={() => {
            openLink(item.file.originalUrl);
          }}
        >
          <CardMessage title={item.name} date={item.updatedAtShort}>
            {item.description}
          </CardMessage>
        </PressRow>
      )}
      keyExtractor={(item) => item.id}
      refreshControl={
        <RefreshControl
          refreshing={isLoading}
          onRefresh={() => {
            onRetrieve();
          }}
        />
      }
    />
  );
};
