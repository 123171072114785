import React, { useState } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';

const Background = styled.View`
  margin-vertical: 8px;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  margin-top: 16px;
`;

const RequiredText = styled.Text`
  color: ${(props) => props.theme.colors.error.red};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
`;
// font-family: "SF Pro Display";

const Container = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
`;

const HeaderText = styled.Text`
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

const Pressable = styled.Pressable`
  background-color: ${(props) => props.theme.colors.dateBackgroundGrey};
  padding-vertical: 6px;
  padding-horizontal: 16px;
  border-radius: 4px;
`;

const DateText = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

const DatePicker = styled(DateTimePicker).attrs({
  mode: 'date',
  is24Hour: true,
  display: 'default',
})`
  flex: 1;
`;

const formattedDate = (date) => {
  return format(date, 'd MMM yyyy');
};

const isiOS = () => {
  return Platform.OS === 'ios';
};

export const FormFieldDate = ({ label, required, value, onUpdated }) => {
  const [date, setDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    setDate(currentDate);
    onUpdated(currentDate);
  };

  return (
    <>
      <Background>
        <Container>
          <HeaderContainer>
            <HeaderText>{label}</HeaderText>
            {required && <RequiredText>(Required)</RequiredText>}
          </HeaderContainer>

          {isiOS() ? (
            <DatePicker
              testID="dateTimePicker"
              value={date}
              onChange={onChange}
            />
          ) : (
            <Pressable onPress={() => setShowDatePicker(true)}>
              <DateText>{formattedDate(date)}</DateText>
            </Pressable>
          )}
          {showDatePicker && (
            <DatePicker
              testID="dateTimePicker"
              value={date}
              onChange={(event, selectedDate) => {
                setShowDatePicker(false);
                onChange(event, selectedDate);
              }}
            />
          )}
        </Container>
      </Background>
    </>
  );
};
