import styled from 'styled-components/native';

export const AccountContainer = styled.View`
  padding: ${(props) => props.theme.space[3]};
  width: 100%;
  max-width: 420px;
`;

export const AuthFields = styled.View`
  background-color: ${(props) => props.theme.colors.pure.white};
  border-color: ${(props) => props.theme.colors.border};
  border-width: 0.5px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const AuthInputBorder = styled.View`
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

export const AuthInput = styled.TextInput`
  height: 24px;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0px;
  margin-top: 8px;
  margin-bottom: 12px;
  margin-horizontal: 16px;
`;

export const ErrorContainer = styled.View`
  align-items: center;
  align-self: center;
  margin: ${(props) => props.theme.space[2]};
`;
