import React, { createContext, useState } from 'react';
import {
  getAppVersions,
  getCurrentAppVersion,
  appVersionsTransform,
  appVersionTransform,
} from './app-versions.service';

export const AppVersionsContext = createContext();

export const AppVersionsContextProvider = ({ children }) => {
  const [appVersions, setAppVersions] = useState([]);
  const [currentAppVersion, setCurrentAppVersion] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const _getAppVersions = async () => {
    setIsLoading(true);
    setAppVersions([]);
    await getAppVersions()
      .then(appVersionsTransform)
      .then((results) => {
        setIsLoading(false);
        setAppVersions(results);
        setError(null);
      })
      .catch((err) => {
        setIsLoading(false);
        setAppVersions([]);
        setError(err);
      });
  };

  const _getCurrentAppVersion = async () => {
    setIsLoading(true);
    setCurrentAppVersion({});
    await getCurrentAppVersion()
      .then(appVersionTransform)
      .then((results) => {
        setIsLoading(false);
        setCurrentAppVersion(results);
        setError(null);
      })
      .catch((err) => {
        setIsLoading(false);
        setAppVersions([]);
        setError(err);
      });
  };

  return (
    <AppVersionsContext.Provider
      value={{
        appVersions,
        currentAppVersion,
        isLoading,
        error,
        getAppVersions: _getAppVersions,
        getCurrentAppVersion: _getCurrentAppVersion,
      }}
    >
      {children}
    </AppVersionsContext.Provider>
  );
};
