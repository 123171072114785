import camelize from 'camelize';
import { format } from 'date-fns';
import { host } from '../../utils/env';

const formattedDate = (isodate) => {
  const date = new Date(isodate);
  if (date.getFullYear() === new Date().getFullYear()) {
    return format(date, 'dd MMM');
  } else {
    return format(date, 'dd MMM yyyy');
  }
};

export const getClubUsers = async ({ token, club }) => {
  return fetch(`${host}/v1/simpler/clubs/${club.id}/club_users`, {
    headers: token,
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const createClubUser = async ({ token, club, clubUser }) => {
  return fetch(`${host}/v1/simpler/clubs/${club.id}/club_users`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...token,
    },
    body: JSON.stringify(clubUser),
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const updateCluUser = async ({ token, club, clubUser }) => {
  return fetch(
    `${host}/v1/simpler/clubs/${club.id}/club_users/${clubUser.id}`,
    {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...token,
      },
      body: JSON.stringify({ clubUser }),
    },
  )
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const removeClubUser = async ({ token, club, clubUser }) => {
  return fetch(
    `${host}/v1/simpler/clubs/${club.id}/club_users/${clubUser.id}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...token,
      },
    },
  )
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const clubUserTransform = (result = {}) => {
  result.createdAtShort = formattedDate(result.created_at);
  result.updatedAtShort = formattedDate(result.updated_at);
  return camelize(result);
};

export const clubUsersTransform = (results = []) => {
  return results.map((result) => clubUserTransform(result));
};
