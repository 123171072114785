import camelize from 'camelize';
import { format } from 'date-fns';
import { host } from '../../utils/env';

const formattedDate = (isodate) => {
  const date = new Date(isodate);
  if (date.getFullYear() === new Date().getFullYear()) {
    return format(date, 'dd MMM');
  } else {
    return format(date, 'dd MMM yyyy');
  }
};

export const getPlayers = async ({ token, club, team }) => {
  return fetch(`${host}/v1/clubs/${club.id}/club_players?team=${team || ''}`, {
    headers: token,
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const searchPlayers = async ({ token, club, team, search }) => {
  return fetch(
    `${host}/v1/clubs/${club.id}/club_players?team=${
      team || ''
    }&q[search_i_cont]=${search}&per=25`,
    {
      headers: token,
    },
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

// export const createPlayer = async ({ token, club, player }) => {
//   return fetch(
//     `${host}/v1/clubs/${club.id}/club_players/${player.id}`,
//     {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         ...token,
//       },
//       body: JSON.stringify({ club }),
//     }
//   )
//     .then((response) => response.json())
//     .catch((error) => {
//       return { errors: [error] };
//     });
// };

export const updatePlayer = async ({ token, club, player }) => {
  return fetch(`${host}/v1/clubs/${club.id}/club_players/${player.id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...token,
    },
    body: JSON.stringify({
      club_player: { medical_notes: player.medicalNotes },
    }),
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

// export const removePlayer = async ({ token, club, player }) => {
//   return fetch(
//     `${host}/v1/clubs/${club.id}/club_players/${player.id}`,
//     {
//       method: "DELETE",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         ...token,
//       },
//     }
//   )
//     .then((response) => response.json())
//     .catch((error) => {
//       return { errors: [error] };
//     });
// };

export const playerTransform = (result = {}) => {
  result.createdAtShort = formattedDate(result.created_at);
  result.updatedAtShort = formattedDate(result.updated_at);
  return camelize(result);
};

export const playersTransform = (results = []) => {
  return results.map((result) => playerTransform(result));
};
