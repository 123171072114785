import React from 'react';
import styled from 'styled-components/native';

const Container = styled.View`
  width: 100%;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  margin-top: 16px;
`;

const HeaderText = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;
// font-family: "SF Pro Display";

const RequiredText = styled.Text`
  color: ${(props) => props.theme.colors.error.red};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
`;
// font-family: "SF Pro Display";

const RowSelection = styled.Pressable`
  margin-top: 2px;
  margin-bottom: 5px;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

const RowContainer = styled.View`
  width: 100%;
  flex-direction: row;
  padding: 16px;
  align-items: flex-start;
`;

const ValuesContainer = styled.View`
  flex: 1;
  flex-direction: column;
`;

const LabelText = styled.Text`
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  padding-right: 16px;
`;
// font-family: "SF Pro Display";

const ValueText = styled.Text`
  color: ${(props) => props.theme.colors.selectedGrey};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  padding-horizontal: 16px;
`;
// font-family: "SF Pro Display";

const Extra = styled.Text`
  color: ${(props) => props.theme.colors.selectedGrey};
`;

// import Forward from "../../../assets/chevron_forward.svg";
// const Icon = styled(Forward).attrs({ width: 19, height: 19 })``;
const Icon = styled.View``;

export const FormFieldSelect = ({
  label,
  header,
  required,
  values,
  extra,
  onSelected,
}) => {
  return (
    <Container>
      {header && (
        <HeaderContainer>
          <HeaderText>{header}</HeaderText>
          {required && <RequiredText>(Required)</RequiredText>}
        </HeaderContainer>
      )}
      <RowSelection onPress={onSelected}>
        <RowContainer>
          <LabelText>{label}</LabelText>
          <ValuesContainer>
            {values?.map((v) => {
              return <ValueText key={v}>{v}</ValueText>;
            })}
          </ValuesContainer>
          {onSelected ? <Icon /> : <Extra>{extra}</Extra>}
        </RowContainer>
      </RowSelection>
    </Container>
  );
};
