import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAppModules, appModulesTransform } from './app-modules.service';
import { AuthenticationContext } from '../authentication/authentication.context';

export const AppModulesContext = createContext();

export const AppModulesContextProvider = ({ children }) => {
  const { user, token } = useContext(AuthenticationContext);

  const [appModules, setAppModules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onRetrieve = async () => {
    setIsLoading(true);
    setAppModules([]);
    await getAppModules({ token })
      .then(appModulesTransform)
      .then((results) => {
        setIsLoading(false);
        setAppModules(results);
        setError(null);
      })
      .catch((err) => {
        setIsLoading(false);
        setAppModules([]);
        setError(err);
      });
  };

  useEffect(() => {
    if (user) {
      onRetrieve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <AppModulesContext.Provider
      value={{
        appModules,
        isLoading,
        error,
        onRetrieve,
      }}
    >
      {children}
    </AppModulesContext.Provider>
  );
};
