import React from 'react';

import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import { ReportsScreen } from '../../features/reports/screens/reports.screen';
import { ReportDetailScreen } from '../../features/reports/screens/report-detail.screen';
import { CreateReportScreen } from '../../features/reports/screens/create-report.screen';
import { ListSelection } from '../../features/reports/screens/list-selection.screen';
import { PlayerSelection } from '../../features/reports/screens/player-selection.screen';

const ReportsStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const ReportsNavigator = () => {
  return (
    <ReportsStack.Navigator screenOptions={createScreenOptions}>
      <ReportsStack.Screen name="ReportsScreen" component={ReportsScreen} />
      <ReportsStack.Screen
        name="ReportDetailScreen"
        component={ReportDetailScreen}
      />
      <ReportsStack.Screen
        name="CreateReportScreen"
        component={CreateReportScreen}
      />
      <ReportsStack.Screen name="ListSelection" component={ListSelection} />
      <ReportsStack.Screen name="PlayerSelection" component={PlayerSelection} />
    </ReportsStack.Navigator>
  );
};
