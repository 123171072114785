import { host } from '../../utils/env';

export const loginRequest = (email, password) => {
  return fetch(`${host}/v1/auth/sign_in`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .then((res) =>
      res.json().then((json) => ({
        headers: res.headers,
        json,
      })),
    )
    .then(({ headers, json }) => {
      if (json.success === false) {
        return json;
      } else {
        const token = {
          'access-token': headers.get('access-token'),
          client: headers.get('client'),
          uid: headers.get('uid'),
        };
        return { token, user: json.data };
      }
    })
    .catch((error) => {
      return { errors: [error] };
    });
};

export const validateTokenRequest = async ({ token }) => {
  if (token && Object.keys(token).length === 0) {
    return {};
  }
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...token,
    },
  };
  return fetch(`${host}/v1/auth/validate_token`, requestOptions).then(
    (response) => response.json(),
  );
};

export const logoutRequest = async ({ token }) => {
  return fetch(`${host}/v1/auth/sign_out`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(token),
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const deleteAccountRequest = async ({ token }) => {
  return fetch(`${host}/v1/auth`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(token),
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const resetPasswordRequest = async (email) => {
  return fetch(`${host}/v1/auth/password`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
    }),
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const resetPassword = async (
  password,
  passwordConfirmation,
  resetToken,
) => {
  return fetch(`${host}/v1/auth/password`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password,
      password_confirmation: passwordConfirmation,
      reset_password_token: resetToken,
    }),
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const registerRequest = (
  firstName,
  lastName,
  email,
  password,
  passwordConfirmation,
) => {
  return fetch(`${host}/v1/auth`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      password: password,
      password_confirmation: passwordConfirmation,
      user_profile_attributes: {
        name: firstName,
        last_name: lastName,
      },
    }),
  })
    .then((res) =>
      //TODO: - setToken to context
      // setTokenFromHeaders(response.headers);
      res.json().then((json) => ({
        headers: res.headers,
        json,
      })),
    )
    .then(({ headers, json }) => {
      if (json.success === false) {
        return json;
      } else {
        const token = {
          'access-token': headers.get('access-token'),
          client: headers.get('client'),
          uid: headers.get('uid'),
        };
        return { token, user: json.data };
      }
    })
    .catch((error) => {
      return { errors: [error] };
    });
};
