import React, { createContext, useContext, useEffect, useState } from 'react';
import { getMe, profileTransform } from './profile.service';
import { AuthenticationContext } from '../authentication/authentication.context';

export const ProfileContext = createContext();

export const ProfileContextProvider = ({ children }) => {
  const { token } = useContext(AuthenticationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [error, setError] = useState('');
  const [currentClub, setCurrentClub] = useState(null);
  const [currentTeam, setCurrentTeam] = useState(null);

  useEffect(() => {
    if (profile.clubs && profile.clubs.length > 0) {
      if (!currentClub) {
        setCurrentClub(profile.clubs[0]);
      }
    }
  }, [profile, currentClub]);

  useEffect(() => {
    if (profile.clubTeams && profile.clubTeams.length === 1) {
      if (!currentTeam) {
        setCurrentTeam(profile.clubTeams[0]);
      }
    }
  }, [profile, currentTeam]);

  const onMe = () => {
    setIsLoading(true);
    getMe({ token })
      .then(profileTransform)
      .then((response) => {
        if (response.errors) {
          setIsLoading(false);
          setError(response.errors);
          setProfile({});
        } else {
          setIsLoading(false);
          setError('');
          setProfile(response);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  useEffect(() => {
    onMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        isLoading,
        error,
        currentClub,
        currentTeam,
        onMe,
        setCurrentClub,
        setCurrentTeam,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
