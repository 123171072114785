import React from 'react';
import styled from 'styled-components/native';
import Svg, { Circle } from 'react-native-svg';
// import Icon from "../../../assets/icons/user-full_white.svg";
const Icon = styled.View``;

// import Plus from "../../../assets/icons/add-full_blue.svg";
const Plus = styled.View``;

const Container = styled.View`
  padding-top: ${(props) => props.theme.space[3]};
  padding-bottom: ${(props) => props.theme.space[1]};
  align-items: center;
`;

const UserIcon = styled(Icon).attrs({
  width: 52,
  height: 52,
})`
  position: absolute;
  top: 26px;
  left: 26px;
`;

const PlusIcon = styled(Plus).attrs({
  width: 32,
  height: 32,
})``;

const PlusIconPressable = styled.Pressable`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 17px;
  background-color: #fff;
  padding: 2px;
`;

const ProfileCircle = () => (
  <Svg height="104" width="104">
    <Circle cx="52" cy="52" r="52" fill="#a5a5a5" />
  </Svg>
);

const AvatarContainer = styled.View``;
export const ProfileImage = ({ onPress }) => {
  return (
    <Container>
      <AvatarContainer>
        <ProfileCircle />
        <UserIcon />
        <PlusIconPressable onPress={onPress}>
          <PlusIcon />
        </PlusIconPressable>
      </AvatarContainer>
    </Container>
  );
};
