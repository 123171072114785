import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import {
  Background,
  BarHeaderSmall,
  ButtonBlue,
  ButtonWhite,
  CardInformation,
  Centered,
  FormFieldText,
  VerticalScroll,
} from '../../../components/ui/';
import { DocumentsContext } from '../../../services/documents/documents.context';
import * as DocumentPicker from 'expo-document-picker';
import * as Linking from 'expo-linking';
import { openLink } from '../../../utils/links';

const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const DocumentDetailScreen = ({ route, navigation }) => {
  const { onCreate, onUpdate } = useContext(DocumentsContext);

  const [id, setId] = useState(null);
  const [date, setDate] = useState('');
  const [file, setFile] = useState({});
  const [document, setDocument] = useState({});
  const [filename, setFilename] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const pickFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result?.type === 'success') {
      setFile(result);
      if (!name) {
        setName(result.name);
      }
      setFilename(result.name);
    }
  };

  const canSave = () => {
    return file !== {} && name !== '';
  };

  useEffect(() => {
    if (route.params?.document) {
      setDocument(route.params?.document);
      setId(route.params?.document.id);
      setDate(route.params?.document.updatedAtShort);
      setFilename(route.params?.document.file.filename);
      setName(route.params?.document.name);
      setDescription(route.params?.document.description);
    }
  }, [route.params?.document]);

  return (
    <Background>
      <BarHeaderSmall
        title={id ? 'Update Document' : 'New Document'}
        leftButtonTitle="Back"
        onLeftPress={() => {
          if (canSave()) {
          }
          navigation.goBack();
        }}
      />
      <VerticalScroll>
        <Centered>
          <CardInformationRow>
            <CardInformation title="Last Updated">{date}</CardInformation>
            <CardInformation title="Filename">{filename}</CardInformation>
          </CardInformationRow>
          <ButtonWhite label="Pick a file" onPress={() => pickFile()} />
          <FormFieldText label="Title" value={name} onChangeText={setName} />
          <FormFieldText
            label="Description"
            value={description}
            onChangeText={setDescription}
          />
          <ButtonBlue
            label={id ? 'Update' : 'Upload'}
            isDisabled={!canSave()}
            onPress={() => {
              if (id) {
                onUpdate({ id, name, description, file });
              } else {
                onCreate({ name, description, file });
              }
              navigation.goBack();
            }}
          />
          <ButtonWhite
            label="View"
            onPress={() => {
              openLink(document.file.originalUrl);
            }}
          />
        </Centered>
      </VerticalScroll>
    </Background>
  );
};
