import React from 'react';
import ExpoStatusBar from 'expo-status-bar/build/ExpoStatusBar';
import { LogBox } from 'react-native';
import * as Linking from 'expo-linking';

import { ThemeProvider } from 'styled-components/native';
import { theme } from './src/infrastructure/theme';
import { Navigation } from './src/infrastructure/navigation/';

import { AuthenticationContextProvider } from './src/services/authentication/authentication.context';
import { AppVersionsContextProvider } from './src/services/app-versions/app-versions.context';

const config = {
  screens: {
    CreatePassword: 'create-new-password',
  },
};

const linking = {
  prefixes: [Linking.createURL('/'), 'https://clubportal.simpler.software'],
  config,
};

export default function App() {
  LogBox.ignoreLogs([
    "[react-native-gesture-handler] Seems like you're using an old API with gesture components, check out new Gestures system!",
  ]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <AuthenticationContextProvider>
          <AppVersionsContextProvider>
            <Navigation linking={linking} />
          </AppVersionsContextProvider>
        </AuthenticationContextProvider>
      </ThemeProvider>
      <ExpoStatusBar style="light" />
    </>
  );
}
