import camelize from 'camelize';
import { format } from 'date-fns';

import { host } from '../../utils/env';

const formattedDate = (isodate) => {
  const date = new Date(isodate);
  if (date.getFullYear() === new Date().getFullYear()) {
    return format(date, 'dd MMM');
  } else {
    return format(date, 'dd MMM yyyy');
  }
};

export const getAppVersions = async () => {
  return fetch(`${host}/v1/app_versions`)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const getCurrentAppVersion = async () => {
  return fetch(`${host}/v1/app_versions/current`)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const appVersionTransform = (result = {}) => {
  result.releasedAtShort = formattedDate(result.released_at);
  return camelize(result);
};

export const appVersionsTransform = (results = []) => {
  return results.map((result) => appVersionTransform(result));
};
