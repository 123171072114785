export const adviceGiven = [
  {
    key: 'Immediate return - unrestricted',
    title: 'Immediate return - unrestricted',
  },
  { key: 'Return with restriction', title: 'Return with restriction' },
  {
    key: 'Unable to return until clearance given',
    title: 'Unable to return until clearance given',
  },
  {
    key: 'Referred to medical practitioner',
    title: 'Referred to medical practitioner',
  },
  { key: 'Referred to hospital', title: 'Referred to hospital' },
  { key: 'Ambulance transport', title: 'Ambulance transport' },
];
