import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/native';
import { DataTable } from 'react-native-paper';
import { Loading } from '../../../components/ui';
import { ClubUsersContext } from '../../../services/club-users/club-users.context';
// import Plus from "../../../../assets/icons/add-full_blue.svg";
const Plus = styled.Text``;

const Container = styled.View`
  padding-horizontal: 16px;
`;
const HeaderContainer = styled.View`
  align-items: flex-end;
  padding-bottom: 8px;
`;
const DataTableView = styled(DataTable)`
  background-color: white;
`;
const ScrollView = styled.ScrollView``;
const optionsPerPage = [2, 3, 4];
const PlusIcon = styled(Plus).attrs({ width: 32, height: 32 })``;
const BarButton = styled.Pressable`
  padding-vertical: 6px;
  padding-horizontal: 8px;
`;

export const ClubUsers = ({ club, navigation }) => {
  const { clubUsers, onRetrieve, isLoading } = useContext(ClubUsersContext);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(optionsPerPage[0]);

  useEffect(() => {
    onRetrieve({ club });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [club]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Container>
        <HeaderContainer>
          <BarButton
            onPress={() =>
              navigation.navigate('ClubUserInviteScreen', { club })
            }
          >
            <PlusIcon>+</PlusIcon>
          </BarButton>
        </HeaderContainer>
        <DataTableView>
          <DataTable.Header>
            <DataTable.Title>Email</DataTable.Title>
            <DataTable.Title>Name</DataTable.Title>
            <DataTable.Title>User Type</DataTable.Title>
            <DataTable.Title>Last Logged In</DataTable.Title>
          </DataTable.Header>
          <ScrollView>
            {clubUsers.map((clubUser) => (
              <DataTable.Row
                key={clubUser.id}
                onPress={() => {
                  navigation.navigate('ClubUserDetailScreen', { clubUser });
                }}
              >
                <DataTable.Cell>{clubUser.user.email}</DataTable.Cell>
                <DataTable.Cell>{clubUser.user.name}</DataTable.Cell>
                <DataTable.Cell>{clubUser.clubUserType}</DataTable.Cell>
                <DataTable.Cell>{clubUser.updatedAtShort}</DataTable.Cell>
              </DataTable.Row>
            ))}
          </ScrollView>
        </DataTableView>
        <DataTable.Pagination
          page={page}
          numberOfPages={1}
          onPageChange={(p) => setPage(p)}
          label="1 of 1"
          optionsPerPage={optionsPerPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          showFastPagination
          optionsLabel={'Rows per page'}
        />
      </Container>
    </>
  );
};
