import React, { useContext, useEffect } from 'react';
import { Alert } from 'react-native';
import styled from 'styled-components/native';
import {
  Background,
  BarHeaderLarge,
  Loading,
  VerticalScroll,
} from '../../../components/ui';
import {
  sizeClasses,
  SizeClassView,
} from '../../../components/utility/size-class-view.component';

import PlusPNG from '../../../../assets/icons/add-full_blue.png';
const PlusIcon = styled.Image.attrs({
  source: PlusPNG,
})`
  width: 24px;
  height: 24px;
`;

const RefreshTest = styled.Text`
  color: ${(props) => props.theme.colors.simple.blue};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;
const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 4px;
`;
import { ClubListRegular } from '../components/club-list-regular.component';
import { ClubListCompact } from '../components/club-list-compact.component';
import { ClubsContext } from '../../../services/clubs/clubs.context';

export const ClubsScreen = ({ navigation }) => {
  const { isLoading, error, onRetrieve } = useContext(ClubsContext);

  useEffect(() => {
    if (error) {
      Alert.alert('Something went wrong', error, [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [error]);

  return (
    <Background>
      <BarHeaderLarge title="Clubs">
        <SizeClassView size={sizeClasses.regular}>
          <BarButton
            onPress={() => {
              onRetrieve();
            }}
          >
            <RefreshTest>Refresh</RefreshTest>
          </BarButton>
        </SizeClassView>
        <BarButton onPress={() => navigation.navigate('ClubUpdateScreen')}>
          <PlusIcon />
        </BarButton>
      </BarHeaderLarge>
      <Loading isLoading={isLoading} />
      <VerticalScroll>
        {/* <SizeClassView size={sizeClasses.compact}>
        <ClubListCompact navigation={navigation} />
      </SizeClassView>
      <SizeClassView size={sizeClasses.regular}> */}
        <ClubListRegular navigation={navigation} />
        {/* </SizeClassView> */}
      </VerticalScroll>
    </Background>
  );
};
