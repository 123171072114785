import React, { useContext, useState } from 'react';
import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { DataTable } from 'react-native-paper';
import { ClubsContext } from '../../../services/clubs/clubs.context';

const Container = styled.View``;

const DataTableView = styled(DataTable)`
  background-color: white;
`;

const optionsPerPage = [2, 3, 4];

export const ClubListRegular = ({ navigation }) => {
  const { clubs } = useContext(ClubsContext);

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(optionsPerPage[0]);

  return (
    <Container>
      <DataTableView>
        <DataTable.Header>
          <DataTable.Title>Club name</DataTable.Title>
          <DataTable.Title style={styles.justCentered}>League</DataTable.Title>
          <DataTable.Title style={styles.justEnd}>Last Updated</DataTable.Title>
        </DataTable.Header>
        {clubs.map((club) => (
          <DataTable.Row
            key={club.id}
            onPress={() => {
              navigation.navigate('ClubDetailScreen', { club });
            }}
          >
            <DataTable.Cell>{club.name}</DataTable.Cell>
            <DataTable.Cell style={styles.justCentered}>
              {club.league}
            </DataTable.Cell>
            <DataTable.Cell style={styles.justEnd}>
              {club.updatedAtShort}
            </DataTable.Cell>
          </DataTable.Row>
        ))}
      </DataTableView>
      <DataTable.Pagination
        page={page}
        numberOfPages={1}
        onPageChange={(p) => setPage(p)}
        label="1 of 1"
        optionsPerPage={optionsPerPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        showFastPagination
        optionsLabel={'Rows per page'}
      />
    </Container>
  );
};

const styles = StyleSheet.create({
  justCentered: {
    justifyContent: 'center',
  },
  justEnd: {
    justifyContent: 'flex-end',
  },
});
