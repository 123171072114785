import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  getReportFiles,
  createReportFile,
  updateReportFile,
  reportFilesTransform,
  reportFileTransform,
} from './report-files.service';
import { AuthenticationContext } from '../authentication/authentication.context';
import { ProfileContext } from '../profile/profile.context';

export const ReportFilesContext = createContext();

export const ReportFilesContextProvider = ({ report, children }) => {
  const { user, token } = useContext(AuthenticationContext);
  const { currentClub } = useContext(ProfileContext);

  const [reportFiles, setReportFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onRetrieve = async () => {
    if (currentClub) {
      setIsLoading(true);
      setReportFiles([]);
      await getReportFiles({ token, report })
        .then(reportFilesTransform)
        .then((results) => {
          setIsLoading(false);
          setReportFiles(results);
          setError(null);
        })
        .catch((err) => {
          setIsLoading(false);
          setReportFiles([]);
          setError(err);
        });
    }
  };

  const onCreate = (file) => {
    if (currentClub) {
      setIsLoading(true);
      createReportFile({ token, report, file })
        .then(reportFileTransform)
        .then((response) => {
          if (response.status === 'error') {
            setIsLoading(false);
            setError(response.errors.full_messages.toString());
          } else {
            setReportFiles([response, ...reportFiles]);
            setIsLoading(false);
            setError('');
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e.toString());
        });
    }
  };

  useEffect(() => {
    if (user) {
      onRetrieve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentClub]);

  return (
    <ReportFilesContext.Provider
      value={{
        reportFiles,
        isLoading,
        error,
        onRetrieve,
        onCreate,
      }}
    >
      {children}
    </ReportFilesContext.Provider>
  );
};
