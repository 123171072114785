import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components/native';
import {
  AvoidingView,
  Background,
  BarHeaderSmall,
  Centered,
  FormTableSelect,
  FormFieldText,
  VerticalScroll,
} from '../../../components/ui/';
import { ChangeTeam } from '../../teams/components/change-team.component';

const Description = styled.Text`
  color: #728596;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  padding-horizontal: 16px;
  padding-bottom: 32px;
`;
// font-family: "SF Pro Display";

const OtherContainer = styled.View`
  padding-top: 16px;
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
`;

import { ProfileContext } from '../../../services/profile/profile.context';
import { PlayersContext } from '../../../services/players/players.context';

export const PlayerSelection = ({ navigation, route }) => {
  const { currentClub, currentTeam } = useContext(ProfileContext);
  const { players, onRetrieve, onPlayerSearch } = useContext(PlayersContext);

  const [playerSearch, setPlayerSearch] = useState('');
  const [title, setTitle] = useState('Select');
  const [header, setHeader] = useState('Select');
  const [description, setDescription] = useState('');
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useLayoutEffect(() => {
    if (route.params?.title) {
      setTitle(route.params?.title);
    }
    if (route.params?.header) {
      setHeader(route.params?.header);
    }
    if (route.params?.description) {
      setDescription(route.params?.description);
    }
    if (route.params?.items) {
    }
    if (route.params?.selectedItems) {
      setItems(route.params?.selectedItems);
      setSelectedItems(route.params?.selectedItems);
      setPlayerSearch(route.params?.selectedItems.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params]);

  useEffect(() => {
    if (currentClub) {
      setTimeout(() => {
        onRetrieve(currentTeam?.name);
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClub, currentTeam]);

  useEffect(() => {
    setItems(
      players
        .map((record) => {
          return {
            id: record.id,
            name: record.firstName + ' ' + record.lastName,
          };
        })
        .sort(),
    );
  }, [players]);

  useEffect(() => {
    if (currentClub) {
      if (playerSearch && playerSearch.length > 0) {
        onPlayerSearch(currentTeam?.name, playerSearch);
      } else {
        onRetrieve(currentTeam?.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerSearch]);

  const onSave = (item) => {
    navigation.navigate('CreateReportScreen', {
      items: item,
    });
  };

  return (
    <>
      <Background>
        {playerSearch ? (
          <BarHeaderSmall
            title={title}
            leftButtonTitle="Cancel"
            onLeftPress={() => {
              navigation.navigate('CreateReportScreen', {});
            }}
            rightButtonTitle="Save"
            onRightPress={() => onSave({ id: null, name: playerSearch })}
          />
        ) : (
          <BarHeaderSmall
            title={title}
            leftButtonTitle="Cancel"
            onLeftPress={() => {
              navigation.navigate('CreateReportScreen', {});
            }}
          />
        )}
        <AvoidingView>
          <ChangeTeam navigation={navigation} />
          <Centered>
            <OtherContainer>
              <FormFieldText
                label="Player Name"
                placeholder="Search"
                value={playerSearch}
                onChangeText={(text) => {
                  setSelectedItems({ id: null, name: text });
                  setPlayerSearch(text);
                }}
              />
            </OtherContainer>
          </Centered>
          <VerticalScroll>
            <Centered>
              <FormTableSelect
                label={header}
                values={items.map((item) => ({
                  key: item.id,
                  value: item.name,
                }))}
                initialKeys={[selectedItems.id]}
                multiselect={false}
                onSelected={(selected) => {
                  const id = selected[0];
                  if (id) {
                    const item = items.find((i) => i.id === id);
                    setSelectedItems(item.name);
                    setTimeout(() => {
                      onSave(item);
                    }, 50);
                  }
                }}
              />
              <Description>{description}</Description>
            </Centered>
          </VerticalScroll>
        </AvoidingView>
      </Background>
    </>
  );
};
