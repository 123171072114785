import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  getReportNotes,
  reportNotesTransform,
  createReportNote,
  reportNoteTransform,
} from './report-notes.service';
import { AuthenticationContext } from '../authentication/authentication.context';
import { ProfileContext } from '../profile/profile.context';

export const ReportNotesContext = createContext();

export const ReportNotesContextProvider = ({ report, children }) => {
  const { user, token } = useContext(AuthenticationContext);
  const { currentClub } = useContext(ProfileContext);

  const [reportNotes, setReportNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onRetrieve = async () => {
    setIsLoading(true);
    setReportNotes([]);
    await getReportNotes({ token, report })
      .then(reportNotesTransform)
      .then((results) => {
        setIsLoading(false);
        setReportNotes(results);
        setError(null);
      })
      .catch((err) => {
        setIsLoading(false);
        setReportNotes([]);
        setError(err);
      });
  };

  const onCreate = (note) => {
    setIsLoading(true);
    if (currentClub) {
      createReportNote({
        token,
        club_id: currentClub.id,
        report_id: report.id,
        note,
      })
        .then(reportNoteTransform)
        .then((response) => {
          if (response.status === 'error') {
            setIsLoading(false);
            setError(response.errors.full_messages.toString());
          } else {
            setReportNotes([response, ...reportNotes]);
            setIsLoading(false);
            setError('');
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e.toString());
        });
    }
  };

  useEffect(() => {
    if (user) {
      onRetrieve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, report]);

  return (
    <ReportNotesContext.Provider
      value={{
        reportNotes,
        isLoading,
        error,
        onCreate,
        onRetrieve,
      }}
    >
      {children}
    </ReportNotesContext.Provider>
  );
};
