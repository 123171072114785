import React, { useContext, useMemo, useState } from 'react';
import { FlatList, Keyboard, Platform, RefreshControl } from 'react-native';
import styled from 'styled-components/native';
import {
  ButtonBlue,
  ButtonWhite,
  CardLargeText,
  Centered,
  FormFieldText,
  Loading,
} from '../../../components/ui/';
import * as DocumentPicker from 'expo-document-picker';
import * as Linking from 'expo-linking';

const ReportFilesList = styled(FlatList).attrs({
  contentContainerStyle: {
    paddingTop: 0,
  },
})``;

import { ReportFilesContext } from '../../../services/report-files/report-files.context';
import { openLink } from '../../../utils/links';

export const ReportFiles = () => {
  const { isLoading, reportFiles, onRetrieve, onCreate } =
    useContext(ReportFilesContext);
  const [file, setFile] = useState({});
  const [filename, setFilename] = useState('');
  const [description, setDescription] = useState('');

  const pickFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result?.type === 'success') {
      setFile(result);
      setFilename(result.name);
    }
  };

  const canSave = useMemo(() => {
    return file && Object.keys(file).length !== 0;
  }, [file]);

  return (
    <>
      <Centered>
        <ButtonWhite label="Pick a file" onPress={() => pickFile()} />
        <FormFieldText label="Filename" value={filename} />
        <FormFieldText
          label="Description"
          value={description}
          onChangeText={setDescription}
        />
        <ButtonBlue
          label={'Upload'}
          isDisabled={!canSave}
          onPress={() => {
            onCreate({ file, description });
            setFile({});
            setFilename('');
            setDescription('');
            Keyboard.dismiss();
          }}
        />
      </Centered>
      <ReportFilesList
        data={reportFiles}
        renderItem={({ item }) => (
          <Centered>
            <CardLargeText
              date={item.createdAtShort}
              title={`${item.file.filename}\n${item.description}`}
              data={[
                { title: 'File format', value: item.file.contentTypeFriendly },
                { title: 'Uploaded by', value: item.uploadedByName },
              ]}
              onPress={() => {
                openLink(item.file.originalUrl);
              }}
            />
          </Centered>
        )}
        keyExtractor={(item) => item.id}
        refreshControl={
          <RefreshControl
            refreshing={isLoading}
            onRefresh={() => {
              onRetrieve();
            }}
          />
        }
      />
      <Loading isLoading={isLoading} />
    </>
  );
};
