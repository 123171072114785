import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'react-native';
import styled from 'styled-components/native';
import {
  Background,
  BarHeaderLarge,
  BarMenu,
  BarSubheader,
  Loading,
  VerticalScroll,
} from '../../../components/ui';
import {
  sizeClasses,
  SizeClassView,
} from '../../../components/utility/size-class-view.component';
import { getReportsExport } from '../../../services/reports/reports.service';

import PlusPNG from '../../../../assets/icons/add-full_blue.png';
const PlusIcon = styled.Image.attrs({
  source: PlusPNG,
})`
  width: 24px;
  height: 24px;
`;

const RefreshTest = styled.Text`
  color: ${(props) => props.theme.colors.simple.blue};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;
const RefreshTestDisabled = styled.Text`
  color: ${(props) => props.theme.colors.grey};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;
const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 4px;
`;
const DownloadText = styled.Text`
  color: ${(props) => props.theme.colors.simple.blue};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding-horizontal: 8px;
  margin-horizontal: 8px;
`;
import { ChangeClub } from '../../clubs/components/change-club.component';
import { ReportListRegular } from '../components/report-list-regular.component';
import { ReportListCompact } from '../components/report-list-compact.component';

import { ReportsContext } from '../../../services/reports/reports.context';
import { AuthenticationContext } from '../../../services/authentication/authentication.context';
import { ProfileContext } from '../../../services/profile/profile.context';

export const ReportsScreen = ({ navigation }) => {
  const { user, token } = useContext(AuthenticationContext);
  const { currentClub } = useContext(ProfileContext);
  const { isLoading, error, onRetrieve } = useContext(ReportsContext);
  const [exportData, setExportData] = useState('');
  const [exportFilename, setExportFilename] = useState('');
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    if (error) {
      Alert.alert('Something went wrong', error, [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [error]);

  const getExportData = () => {
    getReportsExport({ token, club_id: currentClub.id })
      .then((results) => {
        setTimeout(() => {
          setExportData(results);
          const category = 'medical-report';
          const today = new Date().toISOString().split('T')[0];
          setExportFilename(category + '-' + today + '.csv');
          setExporting(false);
        }, 1000);
      })
      .catch(() => {
        setExporting(false);
      });
  };

  const Downloadlink = (props) => {
    const streamData =
      'data:application/octet-stream,' + encodeURIComponent(props.data);
    return (
      <a
        href={streamData}
        download={props.filename}
        style={{ textDecoration: 'none', padding: 0, margin: 0 }}
      >
        {props.children}
      </a>
    );
  };

  return (
    <Background>
      <BarHeaderLarge title="Reports">
        <BarButton onPress={() => navigation.navigate('CreateReportScreen')}>
          <PlusIcon />
        </BarButton>
      </BarHeaderLarge>
      <BarMenu>
        <SizeClassView size={sizeClasses.regular}>
          <BarButton
            onPress={() => {
              setExportData('');
              onRetrieve();
            }}
          >
            <RefreshTest>Refresh</RefreshTest>
          </BarButton>
          {!exportData && (
            <BarButton
              onPress={() => {
                if (!exporting) {
                  setExporting(true);
                  getExportData();
                }
              }}
            >
              {exporting ? (
                <RefreshTestDisabled>Generating</RefreshTestDisabled>
              ) : (
                <RefreshTest>Generate CSV</RefreshTest>
              )}
            </BarButton>
          )}
          {exportData && (
            <BarButton>
              <Downloadlink data={exportData} filename={exportFilename}>
                <DownloadText>Export CSV</DownloadText>
              </Downloadlink>
            </BarButton>
          )}
        </SizeClassView>
      </BarMenu>
      {/* <ChangeClub navigation={navigation} /> */}
      <Loading isLoading={isLoading} />
      <SizeClassView size={sizeClasses.compact}>
        <ReportListCompact navigation={navigation} />
      </SizeClassView>
      <SizeClassView size={sizeClasses.regular}>
        <ReportListRegular navigation={navigation} />
      </SizeClassView>
    </Background>
  );
};
