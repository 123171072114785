import React from 'react';
import styled from 'styled-components/native';

const Container = styled.View`
  background-color: ${(props) => props.theme.colors.pure.white};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

const ClosedContainer = styled.View`
  background-color: #f7f7f7;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

const Background = styled.View`
  padding-vertical: 12px;
  padding-horizontal: 16px;
`;

const TitleLine = styled.View`
  flex-direction: row;
  align-items: center;
`;

const Date = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-align: center;
`;
// font-family: "SF Pro Display";

const ClosedDate = styled.Text`
  color: #a5a5a5;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-align: center;
`;
// font-family: "SF Pro Display";

const Title = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  flex: 1
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  margin-right: 16px;
`;
// font-family: "SF Pro Display";

const ClosedTitle = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  flex: 1
  color: #a5a5a5;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  margin-right: 16px;
`;
// font-family: "SF Pro Display";

const Message = styled.Text`
  width: 100%;
  color: #728596;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

const ClosedMessage = styled.Text`
  width: 100%;
  color: #a5a5a5;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

// import Forward from "../../../assets/chevron_forward.svg";
// const Icon = styled(Forward).attrs({ width: 16, height: 16 })``;
const Icon = styled.View``;

export const CardMessage = ({ title, date, state, children }) => {
  return (
    <>
      {state === 'closed' ? (
        <ClosedContainer>
          <Background>
            <TitleLine>
              <ClosedTitle>{title}</ClosedTitle>
              <ClosedDate>{date}</ClosedDate>
              <Icon />
            </TitleLine>
            <ClosedMessage>{children}</ClosedMessage>
          </Background>
        </ClosedContainer>
      ) : (
        <Container>
          <Background>
            <TitleLine>
              <Title>{title}</Title>
              <Date>{date}</Date>
              <Icon />
            </TitleLine>
            <Message>{children}</Message>
          </Background>
        </Container>
      )}
    </>
  );
};
