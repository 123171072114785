import React, { useState, createContext } from 'react';
import {
  loginRequest,
  logoutRequest,
  registerRequest,
  validateTokenRequest,
  resetPassword,
  resetPasswordRequest,
  deleteAccountRequest,
} from './authentication.service';

export const AuthenticationContext = createContext();

export const AuthenticationContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState('');

  const onLogin = (email, password) => {
    setIsLoading(true);
    setError('');
    loginRequest(email, password)
      .then((response) => {
        if (response.success === false) {
          setIsLoading(false);
          setError(response.errors.toString());
        } else {
          setIsLoading(false);
          setToken(response.token);
          setUser(response.user);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onValidateToken = () => {
    setIsLoading(true);
    setError('');
    validateTokenRequest()
      .then((response) => {
        if (response.success === true) {
          setIsLoading(false);
          setUser(response.data);
        } else {
          setIsLoading(false);
          // Silently fail to login is the token isn't valid
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onLogout = () => {
    logoutRequest({ token }).catch((e) => {
      setError(e.toString());
    });
    setToken(null);
    setUser(null);
  };

  const onRegister = (
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
  ) => {
    setIsLoading(true);
    setError('');
    registerRequest(firstName, lastName, email, password, confirmPassword)
      .then((response) => {
        if (response.success === false) {
          setIsLoading(false);
          setError(response.errors.toString());
        } else {
          onLogin(email, password);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onResetPasswordRequest = (email) => {
    setIsLoading(true);
    setError('');
    resetPasswordRequest(email)
      .then((u) => {
        if (u.status === 'error') {
          setIsLoading(false);
          setError(u.errors.full_messages.toString());
        } else {
          setIsLoading(false);
          setUser(u.data);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onResetPassword = (password, confirmationPassword, resetToken) => {
    setIsLoading(true);
    setError('');
    resetPassword(password, confirmationPassword, resetToken)
      .then((u) => {
        if (u.status === 'error') {
          setIsLoading(false);
          setError(u.errors.full_messages.toString());
        } else {
          setIsLoading(false);
          onLogin(u.data.email, password);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onDeleteAccount = () => {
    deleteAccountRequest({ token }).catch((e) => {
      setError(e.toString());
    });
    setToken(null);
    setUser(null);
  };

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        token,
        isLoading,
        error,
        onLogin,
        onLogout,
        onRegister,
        onValidateToken,
        onResetPasswordRequest,
        onResetPassword,
        onDeleteAccount,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
