import React from 'react';
import { Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

const BlackBackground = styled.View`
  flex: 1;
  background-color: ${(props) => props.theme.colors.pure.black};
`;

const SafeArea = styled(SafeAreaView).attrs({
  edges: ['top', 'right', 'left'],
})`
  flex: 1;
`;

const GreyBackground = styled.View`
  flex: 1;
  background-color: ${(props) => props.theme.colors.backgroundGrey};
`;

const Content = styled.View`
  flex: 1;
`;

const WebContent = styled.View`
  flex: 1;
`;

const isWeb = Platform.OS === 'web';

export const Background = ({ children }) => {
  return (
    <>
      {isWeb ? (
        <WebContent>{children}</WebContent>
      ) : (
        <BlackBackground>
          <SafeArea>
            <GreyBackground>
              <Content>{children}</Content>
            </GreyBackground>
          </SafeArea>
        </BlackBackground>
      )}
    </>
  );
};
