import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  getReports,
  createReport,
  updateReport,
  reportTransform,
  reportsTransform,
} from './reports.service';
import { AuthenticationContext } from '../authentication/authentication.context';
import { ProfileContext } from '../profile/profile.context';

export const ReportsContext = createContext();

export const ReportsContextProvider = ({ children }) => {
  const { user, token } = useContext(AuthenticationContext);
  const { currentClub } = useContext(ProfileContext);

  const [keyword, setKeyword] = useState('');
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSearch = (searchKeyword) => {
    setKeyword(searchKeyword);
  };

  const onRetrieve = async () => {
    if (currentClub) {
      setIsLoading(true);
      setReports([]);
      await getReports({ token, club_id: currentClub.id, player_name: keyword })
        .then(reportsTransform)
        .then((results) => {
          setIsLoading(false);
          setReports(results);
          setError(null);
        })
        .catch((err) => {
          setIsLoading(false);
          setReports([]);
          setError(err);
        });
    }
  };

  const onCreate = (report) => {
    if (currentClub) {
      setIsLoading(true);
      createReport({ token, club_id: currentClub.id, report })
        .then(reportTransform)
        .then((response) => {
          if (response.status === 'error') {
            setIsLoading(false);
            setError(response.errors.full_messages.toString());
          } else {
            setReports([response, ...reports]);
            setIsLoading(false);
            setError('');
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e.toString());
        });
    }
  };

  const onUpdate = (report) => {
    if (currentClub) {
      setIsLoading(true);
      updateReport({ token, club_id: currentClub.id, report })
        .then(reportTransform)
        .then((response) => {
          if (response.status === 'error') {
            setIsLoading(false);
            setError(response.errors.full_messages.toString());
          } else {
            const index = reports.findIndex(
              (record) => record.id === response.id,
            );
            reports[index] = response;
            setReports(reports);
            setIsLoading(false);
            setError('');
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e.toString());
        });
    }
  };

  useEffect(() => {
    if (user) {
      onRetrieve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentClub, keyword]);

  return (
    <ReportsContext.Provider
      value={{
        reports,
        isLoading,
        error,
        search: onSearch,
        keyword,
        onRetrieve,
        onCreate,
        onUpdate,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};
