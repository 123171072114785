import React, { useContext, useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import styled from 'styled-components/native';

import {
  AvoidingView,
  ButtonBlue,
  Centered,
  FormFieldText,
  FormFieldTextMulti,
} from '../../../components/ui/';

const CardInformationRow = styled.View`
  flex-direction: row;
`;

import { PlayersContext } from '../../../services/players/players.context';

export const PlayerDetail = ({ player }) => {
  const { onUpdate } = useContext(PlayersContext);

  const [medicalConditions, setMedicalConditions] = useState(
    player.medicalNotes || '',
  );
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing((player.medicalNotes || '') !== medicalConditions);
  }, [medicalConditions, player.medicalNotes]);

  return (
    <AvoidingView>
      <ScrollView>
        <Centered>
          <FormFieldText label="First Name" value={player.firstName} />
          <FormFieldText label="Family Name" value={player.lastName} />
          <FormFieldText label="Date of Birth" value={player.bornOn} />
          <FormFieldText label="Gender" value={player.gender} />
          <FormFieldText label="Suburb" value={player.suburb} />
          <FormFieldText label="Postal Code" value={player.postalCode} />
          <FormFieldText
            label="Guardian Name"
            value={player.guardianFirstname}
          />
          <FormFieldText label="Guardian Mobile" value={player.guardianPhone} />
          <FormFieldText label="Guardian Email" value={player.guardianEmail} />
          <FormFieldText label="School" value={player.school} />
          <FormFieldText label="School Suburb" value={player.schoolSuburb} />
          <FormFieldText
            label="Permission to play"
            value={player.permissionToPlay}
          />
          <FormFieldText label="Year joined" value={player.yearJoined || ''} />
          <FormFieldText label="Preferred Team" value={player.preferredTeam} />
          <FormFieldTextMulti
            label="Medical conditions"
            value={medicalConditions}
            onChangeText={setMedicalConditions}
          />
          <ButtonBlue
            label="Save"
            isDisabled={!isEditing}
            onPress={() => {
              onUpdate({ id: player.id, medicalNotes: medicalConditions });
              setIsEditing(false);
            }}
          />
        </Centered>
      </ScrollView>
    </AvoidingView>
  );
};
