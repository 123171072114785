import React from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

const LinkText = styled.Text`
  margin-top: 0px;
  height: 24px;
  width: 100%;
  text-align: right;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: right;
`;
// font-family: "SF Pro Display";

export const Link = ({ onPress, isDisabled, children }) => {
  return (
    <Pressable
      onPress={onPress}
      disabled={isDisabled}
      style={({ pressed }) => [
        isDisabled
          ? { opacity: 0.3 }
          : pressed
          ? { opacity: 0.7 }
          : { opacity: 1.0 },
      ]}
    >
      <LinkText>{children}</LinkText>
    </Pressable>
  );
};
