import React from 'react';

import { SettingsScreen } from '../../features/settings/screens/settings.screen';
import { CameraScreen } from '../../features/settings/screens/camera.screen';

import {
  createStackNavigator,
  CardStyleInterpolators,
} from '@react-navigation/stack';

const SettingsStack = createStackNavigator();

export const SettingsNavigator = ({ route, navigation }) => {
  return (
    <SettingsStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
        headerMode: 'screen',
      }}
    >
      <SettingsStack.Screen
        options={{ header: () => null }}
        name="SettingsScreen"
        component={SettingsScreen}
      />
      <SettingsStack.Screen
        options={{ header: () => null }}
        name="CameraScreen"
        component={CameraScreen}
      />
    </SettingsStack.Navigator>
  );
};
