import React from 'react';
import styled from 'styled-components/native';

const Container = styled.View`
  flex: 1;
  padding-vertical: 12px
  padding-horizontal: 8px
`;

const Image = styled.Image.attrs({
  source: require('../../../assets/request_image.jpg'),
})`
  width: 100%;
  height: 100%
  border-radius: 4px;
  resize-mode: cover;
`;

export const CardSmallImage = () => {
  return (
    <Container>
      <Image />
    </Container>
  );
};
