import React from 'react';
import styled from 'styled-components/native';

const Container = styled.View`
flex: 1;  
padding-vertical: 12px
  padding-horizontal: 8px
`;

const Background = styled.View`
  flex: 1;
  border-radius: 4px;
  background-color: #1242d2;
`;

const Title = styled.Text.attrs({
  numberOfLines: 2,
  ellipsizeMode: 'tail',
})`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  padding: 16px;
`;
// font-family: "SF Pro Display";

const Subheading = styled.Text`
  height: 12px;
  color: #f4f7f9;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
`;
// font-family: "SF Pro Display";

const Text = styled.Text`
  height: 26px;
  width: 84px;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
`;
// font-family: "SF Pro Display";

const CardRow = styled.View`
  flex: 1;
  flex-direction: row;
  padding-horizontal: 16px;
  padding-bottom: 16px;
`;

const CardDetail = styled.View`
  flex: 1;
`;

export const CardSmallText = ({
  title = 'We have a broken light switch in our boardroom - this wont be shown ',
  data = [
    { title: 'Date', value: '12/09' },
    { title: 'Location', value: 'Level 12' },
  ],
}) => {
  return (
    <Container>
      <Background>
        <Title>{title}</Title>
        <CardRow>
          <CardDetail>
            <Subheading>{data[0].title}</Subheading>
            <Text>{data[0].value}</Text>
          </CardDetail>
          <CardDetail>
            <Subheading>{data[1].title}</Subheading>
            <Text>{data[1].value}</Text>
          </CardDetail>
        </CardRow>
      </Background>
    </Container>
  );
};
