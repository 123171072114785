import React from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import { colors } from '../../../infrastructure/theme/colors';

export const Button = ({ isDisabled, ...props }) => {
  return (
    <Pressable
      onPress={props.onPress}
      disabled={isDisabled}
      style={({ pressed }) => [
        isDisabled
          ? styles.disabled
          : pressed
          ? styles.pressed
          : styles.initial,
        styles.button,
        props.style,
      ]}
    >
      <Text style={styles.text}>{props.title}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 4,
    borderColor: colors.pure.white,
    marginHorizontal: '10%',
  },
  text: {
    fontSize: 16,
    lineHeight: 20,
    fontWeight: '600', //Semibold
    color: 'white',
  },
  initial: {
    backgroundColor: colors.secondaryButton.initial,
    borderWidth: 1,
  },
  pressed: {
    backgroundColor: colors.secondaryButton.pressed,
    borderWidth: 1,
  },
  disabled: {
    backgroundColor: colors.secondaryButton.initial,
    borderWidth: 1,
    opacity: 0.5,
  },
});
