import React, { useLayoutEffect, useState } from 'react';
import { FlatList } from 'react-native';
import styled from 'styled-components/native';

const Container = styled.View`
  width: 100%;
  margin-vertical: 8px;
`;

const LabelContainer = styled.View`
  width: 100%;
  background-color: ${(props) => props.theme.colors.backgroundGrey};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
  padding-bottom: 8px;
`;

const LabelText = styled.Text`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  padding-horizontal: 16px;
`;
// font-family: "SF Pro Display";

const RowContainer = styled.View`
  width: 100%;
  height: 48px;
  flex-direction: row;
  padding-horizontal: 16px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

const RowLabelText = styled.Text`
  height: 24px;
  color: #000000;
  padding-horizontal: 6px;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

const RowIconContainer = styled.View`
  width: 24px;
  height: 24px;
  justify-content: center;
`;

// import Check from "../../../assets/icons/check.svg";
// const Icon = styled(Check).attrs({ width: 16, height: 16 })``;
import CheckPNG from '../../../assets/icons/check.png';
const CheckIcon = styled.Image.attrs({
  source: CheckPNG,
})`
  width: 18px;
  height: 15px;
`;
const Icon = styled(CheckIcon)``;

const PressableRow = styled.Pressable``;

const TableRow = ({ isSelected, onPress, children }) => {
  return (
    <PressableRow onPress={onPress}>
      <RowContainer>
        <RowIconContainer>{isSelected && <Icon />}</RowIconContainer>
        <RowLabelText>{children}</RowLabelText>
      </RowContainer>
    </PressableRow>
  );
};

const TableRowsList = styled.View.attrs({
  contentContainerStyle: {
    paddingTop: 0,
  },
})``;

export const FormTableSelect = ({
  label,
  values,
  initialKeys,
  multiselect,
  onSelected,
}) => {
  const [selectedKeys, setSelectedKeys] = useState([]);

  useLayoutEffect(() => {
    setSelectedKeys(initialKeys);
  }, [initialKeys]);

  const toggleItem = (key) => {
    var keys = [];
    if (multiselect) {
      const index = selectedKeys.indexOf(key);
      if (index > -1) {
        keys = selectedKeys.filter((i) => i !== key);
      } else {
        keys = [...selectedKeys, key];
      }
    } else {
      keys = [key];
    }
    onSelected(keys);
    setSelectedKeys(keys);
  };

  return (
    <Container>
      <LabelContainer>
        <LabelText>{label}</LabelText>
      </LabelContainer>
      <TableRowsList>
        {values.map((item) => (
          <TableRow
            isSelected={selectedKeys?.includes(item.key)}
            onPress={() => toggleItem(item.key)}
            key={item.key}
          >
            {item.value}
          </TableRow>
        ))}
      </TableRowsList>
    </Container>
  );
};
