import React from 'react';
import styled from 'styled-components/native';

const Container = styled.View``;
const Background = styled.View`
  padding: 24px 16px 16px 16px;
  flex-direction: row;
  align-items: center;
`;
const Heading = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  flex: 1;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
`;
// font-family: "SF Pro Display";

// import Icon from "../../../assets/icons/add-full_blue.svg";
const Icon = styled.View``;

const IconContainer = styled.View`
  width: 24px;
  height: 24px;
`;

const PressableIcon = styled.Pressable``;

export const BarSubheader = ({ title, icon, onPress }) => {
  return (
    <Container>
      <Background>
        <Heading>{title}</Heading>
        <IconContainer>
          {onPress && (
            <PressableIcon onPress={onPress}>
              <Icon width={24} height={24} />
            </PressableIcon>
          )}
        </IconContainer>
      </Background>
    </Container>
  );
};
