import React, { useState } from 'react';
import {
  Background,
  BarHeaderSmall,
  BarSwitcher,
  Centered,
} from '../../../components/ui';
import { PlayerDetail } from '../components/player-detail.component';

import { ClubPlayerNotesContextProvider } from '../../../services/club-player-notes/club-player-notes.context';

export const PlayerDetailScreen = ({ navigation, route }) => {
  const { player } = route.params;
  const tabItems = ['Details'];
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <ClubPlayerNotesContextProvider player={player}>
      <Background>
        <BarHeaderSmall
          title={'Player #' + player.id}
          leftButtonTitle="Back"
          onLeftPress={() => navigation.goBack()}
        />
        <Centered>
          <BarSwitcher
            items={tabItems}
            tab={currentTab}
            onSelected={(item) => {
              setCurrentTab(tabItems.findIndex((i) => i === item));
            }}
          />
        </Centered>

        {currentTab === 0 && <PlayerDetail player={player} />}
      </Background>
    </ClubPlayerNotesContextProvider>
  );
};
