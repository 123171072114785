import React from 'react';

import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import { PlayersScreen } from '../../features/players/screens/players.screen';
import { PlayerDetailScreen } from '../../features/players/screens/player-detail.screen';

const PlayersStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const PlayersNavigator = () => {
  return (
    <PlayersStack.Navigator screenOptions={createScreenOptions}>
      <PlayersStack.Screen name="PlayersScreen" component={PlayersScreen} />
      <PlayersStack.Screen
        name="PlayerDetailScreen"
        component={PlayerDetailScreen}
      />
    </PlayersStack.Navigator>
  );
};
