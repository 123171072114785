import React from 'react';

import styled from 'styled-components/native';

const PressableButton = styled.Pressable`
  margin-vertical: 8px;
  margin-horizontal: 16px;
  height: 47px;
  background-color: ${(props) => props.theme.colors.simple.blue};
  border-radius: 4px;
  opacity: ${(props) => (props.isDisabled ? 0.3 : 1.0)};
`;

const Container = styled.View`
  flex: 1
  justify-content: center;
  align-items: center;
`;

const LabelText = styled.Text`
  color: ${(props) => props.theme.colors.pure.white};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

export const ButtonBlue = ({ label, isDisabled, onPress }) => {
  return (
    <PressableButton
      onPress={() => {
        if (!isDisabled) {
          onPress();
        }
      }}
      isDisabled={isDisabled}
    >
      <Container>
        <LabelText>{label}</LabelText>
      </Container>
    </PressableButton>
  );
};
