import React, { useContext, useState } from 'react';
import { Text } from 'react-native';

import { ReportsContext } from '../../../services/reports/reports.context';

import { DataTable } from 'react-native-paper';
import styled from 'styled-components/native';
import { VerticalScroll } from '../../../components/ui';

const Container = styled.View`
  flex: 1;
`;
const DataTableView = styled(DataTable)`
  background-color: white;
  flex: 1;
`;
const DataTableTitleCenter = styled(DataTable.Title).attrs({
  justifyContent: 'center',
})``;
const DataTableCellCenter = styled(DataTable.Cell).attrs({
  justifyContent: 'center',
})``;

const optionsPerPage = [2, 3, 4];

export const ReportListRegular = ({ navigation }) => {
  const { reports } = useContext(ReportsContext);

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(optionsPerPage[0]);
  return (
    <Container>
      <DataTableView>
        <DataTable.Header>
          <DataTable.Title onPress={() => console.log('Player Name')}>
            Player name
          </DataTable.Title>
          {/* <DataTable.Title>Team</DataTable.Title> */}
          <DataTable.Title>injury</DataTable.Title>
          <DataTableTitleCenter>Suspected Concussion</DataTableTitleCenter>
          <DataTableTitleCenter>Status</DataTableTitleCenter>
          <DataTableTitleCenter>Submitted by</DataTableTitleCenter>
          <DataTableTitleCenter>Incident Date</DataTableTitleCenter>
        </DataTable.Header>
        <VerticalScroll>
          {reports.map((report) => (
            <DataTable.Row
              key={report.id}
              onPress={() => {
                if (report.state === 'draft') {
                  navigation.navigate('CreateReportScreen', { report });
                } else {
                  navigation.navigate('ReportDetailScreen', { report });
                }
              }}
              style={{
                backgroundColor:
                  report.state === 'closed' ? '#f7f7f7' : 'white',
              }}
            >
              <DataTable.Cell>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report.state === 'closed' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {report.player}
                </Text>
              </DataTable.Cell>
              {/* <DataTable.Cell>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report.state === 'closed' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                  }}
                >
                  {report.clubPlayer?.clubTeams?.map((team) => team.name)}
                </Text>
              </DataTable.Cell> */}
              {/* <DataTable.Cell>{report.team}</DataTable.Cell> */}
              <DataTable.Cell>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report.state === 'closed' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                  }}
                >
                  {report.injury.split('|||').filter(Boolean).join(', ')}
                </Text>
              </DataTable.Cell>
              <DataTableCellCenter>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report.state === 'closed' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                  }}
                >
                  {report.concussion ? 'Yes' : 'No'}
                </Text>
              </DataTableCellCenter>
              <DataTableCellCenter>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report.state === 'closed' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                  }}
                >
                  {report.stateShort}
                </Text>
              </DataTableCellCenter>
              <DataTableCellCenter>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report.state === 'closed' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                  }}
                >
                  {report.createdByName}
                </Text>
              </DataTableCellCenter>
              <DataTableCellCenter>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report.state === 'closed' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                  }}
                >
                  {report.incidentAtShort}
                </Text>
              </DataTableCellCenter>
            </DataTable.Row>
          ))}
        </VerticalScroll>
      </DataTableView>
    </Container>
  );
};
