import React, { useContext, useEffect } from 'react';
import { Alert, Platform } from 'react-native';
import styled from 'styled-components/native';
import {
  Background,
  BarHeaderLarge,
  ButtonWhite,
  Centered,
  FormFieldText,
  FormFieldTextMulti,
  ProfileImage,
  VerticalScroll,
} from '../../../components/ui';
import { Spacer } from '../../../components/spacer/spacer.component';

const LogoutText = styled.Text`
  color: ${(props) => props.theme.colors.simple.blue};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
`;
const BarButton = styled.Pressable`
  padding-vertical: 6px;
`;

import { AuthenticationContext } from '../../../services/authentication/authentication.context';
import { ProfileContext } from '../../../services/profile/profile.context';

export const SettingsScreen = ({ navigation }) => {
  const { onLogout, onDeleteAccount, user } = useContext(AuthenticationContext);
  const { profile, error } = useContext(ProfileContext);

  useEffect(() => {
    if (error) {
      Alert.alert('Something went wrong', error, [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [error]);

  const deleteAccount = () => {
    const title = 'Permanently delete account';
    const description = 'Are you sure?';
    if (Platform.OS === 'web') {
      // eslint-disable-next-line no-alert
      const result = window.confirm(
        [title, description].filter(Boolean).join('\n'),
      );
      if (result) {
        onDeleteAccount();
      }
    } else {
      Alert.alert(title, description, [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Yes',
          onPress: () => onDeleteAccount(),
        },
      ]);
    }
  };

  return (
    <Background>
      <BarHeaderLarge title="My Account">
        <BarButton onPress={onLogout}>
          <LogoutText>Logout</LogoutText>
        </BarButton>
      </BarHeaderLarge>
      {/* <ChangeClub navigation={navigation} /> */}
      <VerticalScroll>
        <Centered>
          <ProfileImage onPress={() => navigation.navigate('CameraScreen')} />
          <FormFieldText label="First Name" value={profile.name || ''} />
          <FormFieldText label="Last Name" value={profile.lastName || ''} />
          <FormFieldText label="Email Address" value={user.email || ''} />
          {/* <FormFieldText label="Company" value={profile.company} /> */}
          <FormFieldText
            label="Clubs"
            value={profile.clubs.map((item) => item.name).join(', ')}
          />
          <FormFieldText
            label="Teams"
            value={profile?.clubTeams.map((item) => item.name).join(', ')}
          />
          <Spacer size="large" />
          <Spacer size="large" />
          <Spacer size="large" />
          <Spacer size="large" />
          <Spacer size="large" />
          <Spacer size="large" />
          <FormFieldTextMulti
            label="DELETE ACCOUNT"
            value={
              'Deleting the account is a permanent action. Once deleted, your access to the app will be terminated and your personal profile will be deleted.'
            }
            isWarning={true}
          />
          <ButtonWhite label="Delete Account" onPress={deleteAccount} />
        </Centered>
        <Spacer size="large" />
        <Spacer size="large" />
      </VerticalScroll>
    </Background>
  );
};
