import camelize from 'camelize';
import { format } from 'date-fns';
import { host } from '../../utils/env';

export const getMe = async ({ token }) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...token,
    },
  };
  return fetch(`${host}/v1/me`, requestOptions).then((response) =>
    response.json(),
  );
};

export const profileTransform = (result = {}) => {
  result.createdAtShort = formattedDate(result.created_at);
  result.updatedAtShort = formattedDate(result.updated_at);
  return camelize(result);
};

export const profilesTransform = (results = []) => {
  return results.map((result) => profileTransform(result));
};

const formattedDate = (isodate) => {
  const date = new Date(isodate);
  if (date.getFullYear() === new Date().getFullYear()) {
    return format(date, 'dd MMM');
  } else {
    return format(date, 'dd MMM yyyy');
  }
};
