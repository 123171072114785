import camelize from 'camelize';
import { format } from 'date-fns';
import { host } from '../../utils/env';

const formattedDate = (isodate) => {
  const date = new Date(isodate);
  if (date.getFullYear() === new Date().getFullYear()) {
    return format(date, 'dd MMM');
  } else {
    return format(date, 'dd MMM yyyy');
  }
};

export const getClubPlayerNotes = async ({ token, club_id, player_id }) => {
  return fetch(
    `${host}/v1/clubs/${club_id}/club_players/${player_id}/player_notes`,
    {
      headers: token,
    },
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const createClubPlayerNote = async ({
  token,
  club_id,
  player_id,
  note,
}) => {
  return fetch(
    `${host}/v1/clubs/${club_id}/club_players/${player_id}/player_notes`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...token,
      },
      body: JSON.stringify({
        player_note: {
          note: note,
        },
      }),
    },
  )
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const clubPlayerNoteTransform = (result = {}) => {
  result.createdAtShort = formattedDate(result.created_at);
  result.updatedAtShort = formattedDate(result.updated_at);
  return camelize(result);
};

export const clubPlayerNotesTransform = (results = []) => {
  return results.map((player) => clubPlayerNoteTransform(player));
};
