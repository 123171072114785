import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  getClubPlayerNotes,
  clubPlayerNotesTransform,
  createClubPlayerNote,
  clubPlayerNoteTransform,
} from './club-player-notes.service';
import { AuthenticationContext } from '../authentication/authentication.context';
import { ProfileContext } from '../profile/profile.context';

export const ClubPlayerNotesContext = createContext();

export const ClubPlayerNotesContextProvider = ({ player, children }) => {
  const { user, token } = useContext(AuthenticationContext);
  const { currentClub } = useContext(ProfileContext);

  const [playerNotes, setPlayerNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onRetrieve = async () => {
    if (currentClub) {
      setIsLoading(true);
      setPlayerNotes([]);
      await getClubPlayerNotes({
        token,
        club_id: currentClub.id,
        player_id: player.id,
      })
        .then(clubPlayerNotesTransform)
        .then((results) => {
          setIsLoading(false);
          setPlayerNotes(results);
          setError(null);
        })
        .catch((err) => {
          setIsLoading(false);
          setPlayerNotes([]);
          setError(err);
        });
    }
  };

  const onCreate = (note) => {
    setIsLoading(true);
    if (currentClub) {
      createClubPlayerNote({
        token,
        club_id: currentClub.id,
        player_id: player.id,
        note,
      })
        .then(clubPlayerNoteTransform)
        .then((response) => {
          if (response.status === 'error') {
            setIsLoading(false);
            setError(response.errors.full_messages.toString());
          } else {
            setPlayerNotes([response, ...playerNotes]);
            setIsLoading(false);
            setError('');
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e.toString());
        });
    }
  };

  useEffect(() => {
    if (user) {
      onRetrieve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, player]);

  return (
    <ClubPlayerNotesContext.Provider
      value={{
        playerNotes,
        isLoading,
        error,
        onCreate,
        onRetrieve,
      }}
    >
      {children}
    </ClubPlayerNotesContext.Provider>
  );
};
