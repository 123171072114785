import React from 'react';
import styled from 'styled-components/native';

const Container = styled.View`
  flex: 1;
`;

const Scroll = styled.ScrollView.attrs({
  horizontal: false,
})``;

export const VerticalScroll = ({ children }) => {
  return (
    <Container>
      <Scroll>{children}</Scroll>
    </Container>
  );
};
