import React, { useContext, useState } from 'react';
import { Alert } from 'react-native';
import { ActivityIndicator, Colors } from 'react-native-paper';

import {
  AccountContainer,
  AuthFields,
  AuthInputBorder,
  AuthInput,
  ErrorContainer,
} from '../components/account.styles';
import { Spacer } from '../../../components/spacer/spacer.component';
import { Text } from '../../../components/typography/text.component';
import { Button } from '../components/button.component';
import { Background } from '../components/background.component';
import { Link } from '../components/link.component';

import { AuthenticationContext } from '../../../services/authentication/authentication.context';

export const ForgotPasswordScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const { onResetPasswordRequest, isLoading, error } = useContext(
    AuthenticationContext,
  );
  const isSubmittable = () => {
    return email !== '';
  };

  return (
    <Background>
      <AccountContainer>
        <Spacer size="heading" position="bottom">
          <Text variant="accountHeading">Reset Password</Text>
        </Spacer>
        <AuthFields>
          <AuthInputBorder>
            <AuthInput
              value={email}
              placeholder="Username"
              autoComplete="username"
              keyboardType="email-address"
              textContentType="username"
              autoCapitalize="none"
              returnKeyType="next"
              // autoFocus={true}
              onChangeText={setEmail}
            />
          </AuthInputBorder>
        </AuthFields>
        <Link onPress={() => navigation.goBack()}>Back to login.</Link>
        {!error && (
          <ErrorContainer size="large">
            <Text variant="error">{error}</Text>
          </ErrorContainer>
        )}
        <Spacer size="large">
          {!isLoading ? (
            <Button
              title={'Email Me'}
              isDisabled={!isSubmittable()}
              onPress={() => {
                onResetPasswordRequest(email);
                Alert.alert(
                  'Reset Password',
                  'An email has been sent, containing instructions for resetting your password.',
                  error,
                  [{ text: 'OK', onPress: null }],
                );
                navigation.goBack();
              }}
            />
          ) : (
            <ActivityIndicator animating={true} color={Colors.blue300} />
          )}
        </Spacer>
      </AccountContainer>
    </Background>
  );
};
