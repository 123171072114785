import React from 'react';
import styled from 'styled-components/native';

const Background = styled.View`
  width: 100%;
  padding: 0px;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

const Switcher = styled.View`
  width: 100%;
  padding: 4px;
  flex-direction: row-reverse;
`;

export const BarMenu = ({ children }) => {
  return (
    <Background>
      <Switcher>{children}</Switcher>
    </Background>
  );
};
