import React, { useContext, useMemo } from 'react';
import { ScrollView } from 'react-native';
import styled from 'styled-components/native';

import {
  ButtonWhite,
  Centered,
  CardInformation,
  FormFieldSelect,
  FormFieldText,
  FormFieldTextMulti,
} from '../../../components/ui/';
import { reportsTransform } from '../../../services/reports/reports.service';
import { ProfileContext } from '../../../services/profile/profile.context';

const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const ReportDetails = ({ report, navigation }) => {
  const { profile, currentClub } = useContext(ProfileContext);
  const team = report.clubPlayer?.clubTeams[0];
  const team_name = team?.name || 'Not available';
  const age_group = team?.ageGroup.name || 'Not available';

  const canEditReport = useMemo(() => {
    const clubUser = profile.clubUsers.find(
      (u) => (u.club_id = currentClub.id),
    );
    const role = clubUser.clubRole?.name;
    if (role === 'Trainer') {
      return (
        report?.state === 'submitted' && report?.createdBy === profile?.email
      );
    } else if (role === 'Medical Manager') {
      return report?.state === 'submitted';
    }
    return false;
  }, [profile, currentClub, report]);

  return (
    <ScrollView>
      <Centered>
        <CardInformationRow>
          <CardInformation title="Incident Date">
            {report.incidentAtShort}
          </CardInformation>
          <CardInformation title="Status">{report.stateShort}</CardInformation>
        </CardInformationRow>
        <CardInformationRow>
          <CardInformation title="Age Group">{age_group}</CardInformation>
          <CardInformation title="Team">{team_name}</CardInformation>
        </CardInformationRow>
        <FormFieldSelect label="Player" values={[report.player]} />
        <FormFieldSelect
          label="DRABCD"
          values={[report.drabcd ? 'Yes' : 'No']}
        />
        <FormFieldSelect
          label="Suspected concussion"
          values={[report.concussion ? 'Yes' : 'No']}
        />
        <FormFieldSelect label="Injury" values={report.injuries} />
        <FormFieldSelect label="Cause of injury" values={report.injuryCauses} />
        <FormFieldSelect label="Treatment" values={report.treatments} />
        {report.advicesGiven.length > 0 && (
          <FormFieldSelect label="Advice given" values={report.advicesGiven} />
        )}
        <FormFieldSelect
          label="Treatment assisted"
          values={report.treatmentsAssisted}
        />
        <FormFieldSelect
          label="Opposition team"
          values={[report.oppositionTeam]}
        />
        <FormFieldText label="Venue" value={report.venue} extra={'+1'} />
        <FormFieldTextMulti
          label="Additional comments"
          value={report.comment}
        />
        {canEditReport && (
          <ButtonWhite
            label="Edit Report"
            onPress={() => {
              navigation.navigate('CreateReportScreen', { report: report });
            }}
          />
        )}
      </Centered>
    </ScrollView>
  );
};
