import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  getPlayers,
  searchPlayers,
  updatePlayer,
  playerTransform,
  playersTransform,
} from './players.service';
import { AuthenticationContext } from '../authentication/authentication.context';
import { ProfileContext } from '../profile/profile.context';

export const PlayersContext = createContext();

export const PlayersContextProvider = ({ children }) => {
  const { currentClub } = useContext(ProfileContext);
  const { token } = useContext(AuthenticationContext);
  const [players, setPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onRetrieve = async (team) => {
    if (currentClub) {
      setIsLoading(true);
      setPlayers([]);
      await getPlayers({ token, club: currentClub, team })
        .then(playersTransform)
        .then((results) => {
          setIsLoading(false);
          setPlayers(results);
          setError(null);
        })
        .catch((err) => {
          setIsLoading(false);
          setPlayers([]);
          setError(err);
        });
    }
  };

  const onPlayerSearch = async (team, search) => {
    if (currentClub) {
      setIsLoading(true);
      setPlayers([]);
      await searchPlayers({ token, club: currentClub, team, search })
        .then(playersTransform)
        .then((results) => {
          setIsLoading(false);
          setPlayers(results);
          setError(null);
        })
        .catch((err) => {
          setIsLoading(false);
          setPlayers([]);
          setError(err);
        });
    }
  };

  const onUpdate = (player) => {
    if (currentClub) {
      setIsLoading(true);
      updatePlayer({ token, club: currentClub, player })
        .then(playerTransform)
        .then((response) => {
          if (response.status === 'error') {
            setIsLoading(false);
            setError(response.errors.full_messages.toString());
          } else {
            const index = players.findIndex(
              (record) => record.id === response.id,
            );
            players[index] = response;
            setPlayers(players);
            setIsLoading(false);
            setError('');
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e.toString());
        });
    }
  };

  return (
    <PlayersContext.Provider
      value={{
        players,
        isLoading,
        error,
        onRetrieve,
        onPlayerSearch,
        onUpdate,
      }}
    >
      {children}
    </PlayersContext.Provider>
  );
};
