import React, { useContext } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { theme } from '../theme';
import styled from 'styled-components/native';

// import { OverviewNavigator } from "./overview.navigator";
import { ReportsNavigator } from './reports.navigator';
import { ClubsNavigator } from './clubs.navigator';
import { DocumentsNavigator } from './documents.navigator';
import { SettingsNavigator } from './settings.navigator';
import { PlayersNavigator } from './players.navigation';

const Tab = createBottomTabNavigator();

import HomePNG from '../../../assets/icons/home-full_blue.png';
const Home = styled.Image.attrs({
  source: HomePNG,
})``;
import ListPNG from '../../../assets/icons/list_blue.png';
const List = styled.Image.attrs({
  source: ListPNG,
})``;
// import RequestsSVG from "../../../assets/icons/requests.svg";
const RequestsSVG = styled.View``;
// import ClubsSVG from "../../../assets/icons/requests.svg";
const ClubsSVG = styled.View``;
import DocumentPNG from '../../../assets/document.png';
const Document = styled.Image.attrs({
  source: DocumentPNG,
})``;
// import UserSVG from "../../../assets/icons/user-full.svg";
import UserPNG from '../../../assets/icons/user-full_blue.png';
const User = styled.Image.attrs({
  source: UserPNG,
})``;
import SettingsPNG from '../../../assets/icons/settings-full_blue.png';
const Settings = styled.Image.attrs({
  source: SettingsPNG,
})``;
import WarningPNG from '../../../assets/icons/warning-full_blue.png';
const Warning = styled.Image.attrs({
  source: WarningPNG,
})``;
import StarPNG from '../../../assets/icons/star-full_blue.png';
const Star = styled.Image.attrs({
  source: StarPNG,
})``;

const createScreenOptions = ({ route }) => {
  return {
    tabBarIcon: ({ size, color }) => {
      switch (route.name) {
        case 'Overview':
          return (
            <Home
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
        case 'Reports':
          return (
            <Warning
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
        case 'Clubs':
          return (
            <List
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
        case 'Documents':
          return (
            <Document
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
        case 'Players':
          return (
            <Star
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
        case 'Account':
          return (
            <User
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
      }
    },
    tabBarActiveTintColor: theme.colors.simple.blue,
    tabBarInactiveTintColor: theme.colors.grey,
    headerShown: false,
  };
};

import { AuthenticationContext } from '../../services/authentication/authentication.context';

export const AppTabNavigator = () => {
  const { user } = useContext(AuthenticationContext);
  return (
    <Tab.Navigator screenOptions={createScreenOptions}>
      {/* <Tab.Screen name="Overview" component={OverviewNavigator} /> */}
      <Tab.Screen name="Reports" component={ReportsNavigator} />
      {user.user_type === 'simpler' && (
        <Tab.Screen name="Clubs" component={ClubsNavigator} />
      )}
      <Tab.Screen name="Documents" component={DocumentsNavigator} />
      <Tab.Screen name="Players" component={PlayersNavigator} />
      <Tab.Screen name="Account" component={SettingsNavigator} />
    </Tab.Navigator>
  );
};
