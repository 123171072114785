import React, { useContext } from 'react';
import { BarHeaderSmall, FormTableSelect } from '../../../components/ui/';
import styled from 'styled-components/native';

import { ProfileContext } from '../../../services/profile/profile.context';

const Background = styled.View``;
export const ChangeClubScreen = ({ navigation }) => {
  const { profile, currentClub, setCurrentClub } = useContext(ProfileContext);

  return (
    <Background>
      <BarHeaderSmall
        title="Change club"
        leftButtonTitle="Cancel"
        onLeftPress={() => navigation.goBack()}
      />
      <FormTableSelect
        label={''}
        values={profile.clubs.map((club) => ({
          key: club.id,
          value: club.name,
        }))}
        initialKeys={[currentClub?.id]}
        multiselect={false}
        onSelected={(selected) => {
          if (selected.length > 0) {
            const index = profile.clubs.findIndex(
              (club) => club.id === selected[0],
            );
            setCurrentClub(profile.clubs[index]);
            navigation.goBack();
          }
        }}
      />
    </Background>
  );
};
