import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/native';
import { DataTable } from 'react-native-paper';
import { Centered, ButtonBlue, Loading } from '../../../components/ui';
import { ClubPlayersContext } from '../../../services/club-players/club-players.context';

const Container = styled.View`
  padding: 16px;
`;
const DataTableView = styled(DataTable)`
  background-color: white;
`;
const ScrollView = styled.ScrollView``;
const optionsPerPage = [2, 3, 4];
const RefreshTest = styled.Text`
  color: ${(props) => props.theme.colors.simple.blue};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;
const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 4px;
`;

export const ClubPlayers = ({ club, navigation }) => {
  const { clubPlayers, onRetrieve, isLoading } = useContext(ClubPlayersContext);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(optionsPerPage[0]);

  useEffect(() => {
    onRetrieve(club);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [club]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Centered>
        <ButtonBlue
          label="Upload Players List"
          onPress={() => {
            navigation.navigate('ClubPlayerImportScreen', { club });
          }}
        />
        <BarButton
          onPress={() => {
            onRetrieve(club);
          }}
        >
          <RefreshTest>Refresh</RefreshTest>
        </BarButton>
      </Centered>
      <Container>
        <DataTableView>
          <DataTable.Header>
            <DataTable.Title>First Name</DataTable.Title>
            <DataTable.Title>Last Name</DataTable.Title>
            <DataTable.Title>Team</DataTable.Title>
            <DataTable.Title>Medical Notes</DataTable.Title>
            <DataTable.Title>Last Updated</DataTable.Title>
          </DataTable.Header>
          <ScrollView>
            {clubPlayers.map((player) => (
              <DataTable.Row
                key={player.id}
                onPress={() => {
                  console.log('Go to Simpler Player detail screen');
                }}
              >
                <DataTable.Cell>{player.firstName}</DataTable.Cell>
                <DataTable.Cell>{player.lastName}</DataTable.Cell>
                <DataTable.Cell>{player.team}</DataTable.Cell>
                <DataTable.Cell>{player.medicalNotes}</DataTable.Cell>
                <DataTable.Cell>{player.updatedAtShort}</DataTable.Cell>
              </DataTable.Row>
            ))}
          </ScrollView>
        </DataTableView>
        <DataTable.Pagination
          page={page}
          numberOfPages={1}
          onPageChange={(p) => setPage(p)}
          label="1 of 1"
          optionsPerPage={optionsPerPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          showFastPagination
          optionsLabel={'Rows per page'}
        />
      </Container>
    </>
  );
};
