export const oppositionPlayers = [
  { key: 'Ajax', title: 'Ajax' },
  { key: 'Ashwood Magpies', title: 'Ashwood Magpies' },
  { key: 'Beaumaris Sharks', title: 'Beaumaris Sharks' },
  { key: 'Bentleigh Demons', title: 'Bentleigh Demons' },
  { key: 'Brighton Beach Kangaroos', title: 'Brighton Beach Kangaroos' },
  { key: 'Caulfield Bears', title: 'Caulfield Bears' },
  { key: 'Cheltenham Panthers', title: 'Cheltenham Panthers' },
  { key: 'Dingley Dingoes', title: 'Dingley Dingoes' },
  { key: 'East Brighton Vampires', title: 'East Brighton Vampires' },
  { key: 'East Malvern Knights', title: 'East Malvern Knights' },
  { key: 'East Sandringham Zebras', title: 'East Sandringham Zebras' },
  { key: 'Hampton Rovers', title: 'Hampton Rovers' },
  { key: 'Highett Doggies', title: 'Highett Doggies' },
  {
    key: 'Mordialloc-Braeside Mustangs',
    title: 'Mordialloc-Braeside Mustangs',
  },
  { key: 'Murrumbeena Lions', title: 'Murrumbeena Lions' },
  { key: 'Oakleigh Dragons', title: 'Oakleigh Dragons' },
  { key: 'Ormond Monders', title: 'Ormond Monders' },
  { key: 'Port Melbourne Colts', title: 'Port Melbourne Colts' },
  { key: 'Prahran', title: 'Prahran' },
  { key: 'South Melbourne Districts', title: 'South Melbourne Districts' },
  { key: 'St Bedes / Mentone Tigers', title: 'St Bedes / Mentone Tigers' },
  { key: 'St Kilda City', title: 'St Kilda City' },
  { key: 'St Paul’s Mckinnon Doggies', title: 'St Paul’s Mckinnon Doggies' },
  { key: 'St Peters', title: 'St Peters' },
  { key: 'Waverley Park Hawks', title: 'Waverley Park Hawks' },
];
