import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Alert, Platform } from 'react-native';
import { ActivityIndicator, Colors } from 'react-native-paper';
import {
  AccountContainer,
  AuthFields,
  AuthInputBorder,
  AuthInput,
} from '../components/account.styles';
import styled from 'styled-components/native';
import { Spacer } from '../../../components/spacer/spacer.component';
import { Text } from '../../../components/typography/text.component';
import { Button } from '../components/button.component';
import { Background } from '../components/background.component';
import { AuthenticationContext } from '../../../services/authentication/authentication.context';
import { AppVersionsContext } from '../../../services/app-versions/app-versions.context';
import { Link } from '../components/link.component';
import { ActivateLink } from '../components/activate-link.component';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';

const isWeb = Platform.OS === 'web';

const VerticalScroll = styled.ScrollView.attrs({
  horizontal: false,
})``;

export const LoginScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [letMeIn, setLetMeIn] = useState(false);
  const [showingAlert, setShowingAlert] = useState(false);
  const { onValidateToken, onLogin, isLoading, error } = useContext(
    AuthenticationContext,
  );
  const { getCurrentAppVersion, currentAppVersion } =
    useContext(AppVersionsContext);
  const passwordField = useRef(null);

  useEffect(() => {
    setLetMeIn(email !== '' && password !== '');
  }, [email, password]);

  useEffect(() => {
    if (error) {
      Alert.alert('Login Failed', error, [{ text: 'OK', onPress: null }]);
    }
  }, [error]);

  useEffect(() => {
    getCurrentAppVersion();
    //TODO: - Put this back when I get expo-secure-store working again.
    // if (!isWeb) {
    //   onValidateToken();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var hasNewUpdate = useMemo(() => {
    if (isWeb) {
      return false;
    }
    const semver = require('semver');
    const thisVersion = Constants.manifest.version;
    const serverVersion = currentAppVersion.version;
    try {
      if (semver.valid(serverVersion) && semver.valid(thisVersion)) {
        const hasNew = semver.gt(serverVersion, thisVersion);
        return hasNew;
      }
    } catch (e) {
      console.log('hasNewUpdate', e);
    }
    return false;
  }, [currentAppVersion]);

  const openAppStore = () => {
    switch (Platform.OS) {
      case 'android':
        const androidlink =
          'https://play.google.com/store/apps/details?id=fm.simpler.app';
        Linking.canOpenURL(androidlink).then(
          (supported) => {
            supported && Linking.openURL(androidlink);
          },
          (err) => console.log(err),
        );
        break;
      case 'ios':
        const ioslink =
          'itms-apps://apps.apple.com/id/app/simpler-medical-reports/id1501455635?l=id';
        Linking.canOpenURL(ioslink).then(
          (supported) => {
            supported && Linking.openURL(ioslink);
          },
          (err) => console.log(err),
        );
        break;
    }
  };

  useEffect(() => {
    if (hasNewUpdate && !showingAlert) {
      setShowingAlert(true);
      Alert.alert('New App Version Available', currentAppVersion.message, [
        {
          text: 'Update',
          onPress: () => {
            setShowingAlert(false);
            openAppStore();
          },
        },
        {
          text: 'Cancel',
          onPress: () => {
            setShowingAlert(false);
          },
          style: 'cancel',
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNewUpdate, currentAppVersion]);

  return (
    <Background>
      <AccountContainer>
        <Spacer size="heading" position="bottom">
          <Text variant="accountHeading">Log in to Simpler</Text>
        </Spacer>
        <VerticalScroll>
          <AuthFields>
            <AuthInputBorder>
              <AuthInput
                value={email}
                placeholder="Email"
                autoComplete="username"
                keyboardType="email-address"
                textContentType="username"
                autoCapitalize="none"
                returnKeyType="next"
                // autoFocus={true}
                onChangeText={setEmail}
                onSubmitEditing={() => {
                  passwordField.current.focus();
                }}
              />
            </AuthInputBorder>
            <AuthInput
              ref={passwordField}
              value={password}
              placeholder="Password"
              autoComplete="password"
              textContentType="password"
              autoCapitalize="none"
              secureTextEntry={true}
              returnKeyType="go"
              onSubmitEditing={() => onLogin(email, password)}
              onChangeText={setPassword}
            />
          </AuthFields>
          <Link onPress={() => navigation.navigate('ForgotPassword')}>
            Forgot Password?
          </Link>
          <Spacer size="large">
            {!isLoading ? (
              <Button
                title={'Let Me In'}
                isDisabled={!letMeIn}
                onPress={() => {
                  onLogin(email, password);
                }}
              />
            ) : (
              <ActivityIndicator animating={true} color={Colors.blue300} />
            )}
          </Spacer>
          <ActivateLink onPress={() => navigation.navigate('Activate')}>
            Activate Account
          </ActivateLink>
        </VerticalScroll>
      </AccountContainer>
    </Background>
  );
};
