import camelize from 'camelize';
import { format } from 'date-fns';
import { host } from '../../utils/env';

const formattedDate = (isodate) => {
  const date = new Date(isodate);
  if (date.getFullYear() === new Date().getFullYear()) {
    return format(date, 'dd MMM');
  } else {
    return format(date, 'dd MMM yyyy');
  }
};

export const getClubs = async ({ token }) => {
  return fetch(`${host}/v1/simpler/clubs`, {
    headers: token,
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const createClub = async ({ token, club }) => {
  return fetch(`${host}/v1/simpler/clubs`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...token,
    },
    body: JSON.stringify({ club }),
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const updateClub = async ({ token, club }) => {
  return fetch(`${host}/v1/simpler/clubs/${club.id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...token,
    },
    body: JSON.stringify({ club }),
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const clubTransform = (result = {}) => {
  result.createdAtShort = formattedDate(result.created_at);
  result.updatedAtShort = formattedDate(result.updated_at);
  return camelize(result);
};

export const clubsTransform = (results = []) => {
  return results.map((result) => clubTransform(result));
};
