import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { format, parseISO } from 'date-fns';

const Container = styled.View`
  width: 100%;
  margin-vertical: 8px;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  margin-top: 16px;
`;

const HeaderText = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;
// font-family: "SF Pro Display";

const RequiredText = styled.Text`
  color: ${(props) => props.theme.colors.error.red};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
`;
// font-family: "SF Pro Display";
const LabelText = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;
// font-family: "SF Pro Display";

// Web
const InputContainer = styled.View`
  height: 56px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
  padding-horizontal: 16px;
  padding-bottom: 7px;
  justify-content: center;
`;

// iOS/Android
const DatePicker = styled(DateTimePicker).attrs({
  mode: 'date',
  is24Hour: true,
  display: 'default',
})``;

// Android
const Pressable = styled.Pressable`
  background-color: ${(props) => props.theme.colors.dateBackgroundGrey};
  padding: 6px;
  border-radius: 4px;
  width: 120px;
  align-items: flex-end;
`;

const DateText = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

const formattedWebDate = (date) => {
  return format(date, 'yyyy-MM-dd');
};

const formattedAndroidDate = (date) => {
  return format(date, 'd MMM yyyy');
};

export const FormFieldTextDate = ({
  label,
  value,
  required,
  placeholder,
  keyboardType,
  autoCapitalize,
  onUpdated,
}) => {
  const [initialValue, setInitialValue] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    const parsedDate = new Date(value);
    if (parsedDate) {
      switch (Platform.OS) {
        case 'android':
          setInitialValue(parsedDate);
          break;
        case 'ios':
          setInitialValue(parsedDate);
          break;
        case 'web':
          setInitialValue(formattedWebDate(parsedDate));
          break;
      }
    }
  }, [value]);

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>{label}</HeaderText>
        {required && <RequiredText>(Required)</RequiredText>}
      </HeaderContainer>
      <InputContainer>
        {initialValue && Platform.OS === 'web' && (
          <input
            style={{
              flex: 1,
              fontSize: 15,
              border: 0,
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
            }}
            type="date"
            value={initialValue}
            onChange={(event) => {
              setInitialValue(event.target.value);
              const parsedDate = parseISO(event.target.value);
              if (parsedDate.toString() !== 'Invalid Date') {
                onUpdated(parsedDate);
              }
            }}
          />
        )}
        {Platform.OS === 'ios' && (
          <DatePicker
            testID="dateTimePicker"
            value={initialValue}
            onChange={(event, iosValue) => {
              onUpdated(iosValue);
            }}
            style={{ width: 120 }}
          />
        )}
        {initialValue && Platform.OS === 'android' && (
          <Pressable onPress={() => setShowDatePicker(true)}>
            <DateText>
              {initialValue && formattedAndroidDate(initialValue)}
            </DateText>
          </Pressable>
        )}
        {showDatePicker && (
          <DatePicker
            testID="dateTimePicker"
            value={initialValue}
            onChange={(event, selectedDate) => {
              setShowDatePicker(false);
              onUpdated(selectedDate);
              // onChange(event, selectedDate);
            }}
          />
        )}
      </InputContainer>
    </Container>
  );
};
