import React, { useContext, useMemo } from 'react';

import { BarChanger } from '../../../components/ui/';
import { ProfileContext } from '../../../services/profile/profile.context';

export const ChangeTeam = ({ navigation }) => {
  const { profile, currentTeam } = useContext(ProfileContext);
  const teamName = useMemo(() => {
    return currentTeam?.name || 'All my teams';
  }, [currentTeam]);
  return (
    <>
      {profile?.clubTeams?.length > 1 ? (
        <BarChanger
          title={teamName}
          link="Change team"
          onPress={() => navigation.navigate('ChangeTeamScreen')}
        />
      ) : (
        <BarChanger title={teamName} />
      )}
    </>
  );
};
