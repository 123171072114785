import React, { useContext, useEffect, useMemo } from 'react';
import { Alert } from 'react-native';
import styled from 'styled-components/native';
import {
  Background,
  BarHeaderLarge,
  Loading,
  VerticalScroll,
} from '../../../components/ui/';
import { ChangeClub } from '../../clubs/components/change-club.component';

import PlusPNG from '../../../../assets/icons/add-full_blue.png';
const PlusIcon = styled.Image.attrs({
  source: PlusPNG,
})`
  width: 24px;
  height: 24px;
`;

const RefreshTest = styled.Text`
  color: ${(props) => props.theme.colors.simple.blue};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;
const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 4px;
`;
import {
  sizeClasses,
  SizeClassView,
} from '../../../components/utility/size-class-view.component';

import { DocumentListCompact } from '../components/document-list-compact.component';
import { DocumentListRegular } from '../components/document-list-regular.component';

import { DocumentsContext } from '../../../services/documents/documents.context';
import { ProfileContext } from '../../../services/profile/profile.context';

export const DocumentsScreen = ({ navigation }) => {
  const { isLoading, error, onRetrieve } = useContext(DocumentsContext);
  const { profile, currentClub } = useContext(ProfileContext);

  const canAddDocument = useMemo(() => {
    const clubUser = profile.clubUsers.find(
      (u) => (u.club_id = currentClub.id),
    );
    const role = clubUser.clubRole?.name;
    return role !== 'Trainer';
  }, [profile, currentClub]);

  useEffect(() => {
    if (error) {
      Alert.alert('Something went wrong', error, [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [error]);

  return (
    <Background>
      <BarHeaderLarge title="Documents">
        <SizeClassView size={sizeClasses.regular}>
          <BarButton
            onPress={() => {
              onRetrieve();
            }}
          >
            <RefreshTest>Refresh</RefreshTest>
          </BarButton>
        </SizeClassView>
        {canAddDocument && (
          <BarButton
            onPress={() => navigation.navigate('DocumentDetailScreen')}
          >
            <PlusIcon />
          </BarButton>
        )}
      </BarHeaderLarge>
      {/* <ChangeClub navigation={navigation} /> */}
      <Loading isLoading={isLoading} />
      <SizeClassView size={sizeClasses.compact}>
        <DocumentListCompact navigation={navigation} />
      </SizeClassView>
      <SizeClassView size={sizeClasses.regular}>
        <VerticalScroll>
          <DocumentListRegular navigation={navigation} />
        </VerticalScroll>
      </SizeClassView>
    </Background>
  );
};
