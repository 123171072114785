import React from 'react';
import { Pressable } from 'react-native';

import styled from 'styled-components/native';

// import BackIcon from "../../../assets/back.svg";
// const BackIcon = styled.View``;

const Background = styled.View`
  background-color: ${(props) => props.theme.colors.pure.white};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
  padding-vertical: ${(props) => props.theme.space[1]};
  padding-horizontal: ${(props) => props.theme.space[3]};
`;

const Container = styled.View`
  align-items: center;
  justify-content: center;
  padding-vertical: 8px;
`;
// flex-direction: row;

const Title = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
`;
// font-family: "SF Pro Display";

const LeftContainer = styled.View`
  position: absolute;
  left: 16px;
  top: 4px;
`;

const LeftTitle = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  width: 72px;
  color: #1242d2;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

const LeftButton = styled(Pressable)`
  flex-direction: row;
  padding-vertical: 8px;
`;

const RightContainer = styled.View`
  position: absolute;
  right: 16px;
  top: 4px;
`;

const RightButton = styled(Pressable)`
  flex-direction: row;
  align-items: flex-end;
  padding-vertical: 8px;
  text-align: center;
`;

const RightTitle = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  width: 72px;
  color: #1242d2;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
`;
// font-family: "SF Pro Display";

export const BarHeaderSmall = ({
  title,
  leftButtonTitle,
  onLeftPress,
  rightButtonTitle,
  onRightPress,
  children,
}) => {
  return (
    <Background>
      <Container>
        <Title>{title}</Title>
      </Container>
      {onLeftPress && (
        <LeftContainer>
          <LeftButton onPress={onLeftPress}>
            {/* <BackIcon width={24} height={24} /> */}
            <LeftTitle>{leftButtonTitle}</LeftTitle>
          </LeftButton>
        </LeftContainer>
      )}
      {onRightPress && (
        <RightContainer>
          <RightButton onPress={onRightPress}>
            <RightTitle>{rightButtonTitle}</RightTitle>
          </RightButton>
        </RightContainer>
      )}
    </Background>
  );
};
