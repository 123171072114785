import React from 'react';
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';
import { DocumentsScreen } from '../../features/documents/screens/documents.screen';
import { DocumentDetailScreen } from '../../features/documents/screens/document-detail.screen';

const DocumentsStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const DocumentsNavigator = () => {
  return (
    <DocumentsStack.Navigator screenOptions={createScreenOptions}>
      <DocumentsStack.Screen
        name="DocumentsScreen"
        component={DocumentsScreen}
      />
      <DocumentsStack.Screen
        name="DocumentDetailScreen"
        component={DocumentDetailScreen}
      />
    </DocumentsStack.Navigator>
  );
};
