import camelize from 'camelize';
import { format } from 'date-fns';
import { host } from '../../utils/env';

export const getReports = async ({ token, club_id, player_name }) => {
  return fetch(
    `${host}/v1/clubs/${club_id}/reports?player_search=${player_name}`,
    {
      headers: token,
    },
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const getReportsExport = async ({ token, club_id }) => {
  return fetch(`${host}/v1/clubs/${club_id}/reports/export.csv`, {
    headers: token,
  })
    .then((response) => response.text())
    .catch((error) => console.log(error));
};

export const createReport = async ({ token, club_id, report }) => {
  return fetch(`${host}/v1/clubs/${club_id}/reports`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...token,
    },
    body: JSON.stringify({ report }),
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const updateReport = async ({ token, club_id, report }) => {
  return fetch(`${host}/v1/clubs/${club_id}/reports/${report.id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...token,
    },
    body: JSON.stringify({ report }),
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

const formattedDate = (isodate) => {
  const date = new Date(isodate);
  if (date.getFullYear() === new Date().getFullYear()) {
    return format(date, 'dd MMM');
  } else {
    return format(date, 'dd MMM yyyy');
  }
};

export const reportTransform = (report = {}) => {
  switch (report.state) {
    case 'draft':
      report.stateDescription = 'Your request is in draft';
      report.stateShort = 'Draft';
      break;
    case 'submitted':
      report.stateDescription = 'Your request has been completed';
      report.stateShort = 'Submitted';
      break;
    case 'open':
      report.stateDescription = 'Your request has been opened';
      report.stateShort = 'Open';
      break;
    case 'closed':
      report.stateDescription = 'Your request has been closed';
      report.stateShort = 'Closed';
      break;
    default:
      report.stateDescription = '';
      report.stateShort = '';
  }
  report.playerImage = 'https://i.pravatar.cc/300';

  const injuries = report.injury
    ? report.injury.split('|||').filter(Boolean)
    : [];
  const injuryCount = injuries.length;
  report.injuries = injuries;
  report.injuryFirst = injuryCount > 0 ? injuries[0] : '';
  report.injuryExtra = injuryCount > 1 ? '+' + (injuryCount - 1) : '';

  const injuryCauses = report.injuryCause
    ? report.injuryCause.split('|||').filter(Boolean)
    : [];
  const injuryCauseCount = injuryCauses.length;
  report.injuryCauses = injuryCauses;
  report.injuryCauseFirst = injuryCauseCount > 0 ? injuryCauses[0] : '';
  report.injuryCauseExtra =
    injuryCauseCount > 1 ? '+' + (injuryCauseCount - 1) : '';

  const treatments = report.treatment
    ? report.treatment.split('|||').filter(Boolean)
    : [];
  const treatmentCount = treatments.length;
  report.treatments = treatments;
  report.treatmentFirst = treatmentCount > 0 ? treatments[0] : '';
  report.treatmentExtra = treatmentCount > 1 ? '+' + (treatmentCount - 1) : '';

  const advicesGiven = report.adviceGiven
    ? report.adviceGiven.split('|||').filter(Boolean)
    : [];
  const adviceGivenCount = advicesGiven.length;
  report.advicesGiven = advicesGiven;
  report.adviceGivenFirst = adviceGivenCount > 0 ? advicesGiven[0] : '';
  report.adviceGivenExtra =
    adviceGivenCount > 1 ? '+' + (adviceGivenCount - 1) : '';

  const treatmentsAssisted = report.treatmentAssisted
    ? report.treatmentAssisted.split('|||').filter(Boolean)
    : [];
  const treatmentAssistedCount = treatmentsAssisted.length;
  report.treatmentsAssisted = treatmentsAssisted;
  report.treatmentAssistedFirst =
    treatmentAssistedCount > 0 ? treatmentsAssisted[0] : '';
  report.treatmentAssistedExtra =
    treatmentAssistedCount > 1 ? '+' + (treatmentAssistedCount - 1) : '';

  report.incidentAtShort = formattedDate(report.incident_at);
  report.createdAtShort = formattedDate(report.created_at);
  report.updatedAtShort = formattedDate(report.updated_at);
  return camelize(report);
};

export const reportsTransform = (results = []) => {
  return results.map((report) => reportTransform(report));
};
