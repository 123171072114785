import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components/native';
import Svg, { Circle } from 'react-native-svg';

const Container = styled.View`
  width: 100%;
  padding: 16px;
`;

const Switcher = styled.View`
  background-color: ${(props) => props.theme.colors.pure.white};
  border-radius: 8.91px;
  width: 100%;
  padding: 2px;
  flex-direction: row;
`;

const BubbleContainer = styled.View`
  background-color: ${(props) =>
    props.mode === 'active'
      ? props.theme.colors.simple.blue
      : props.theme.colors.pure.white};
  flex: 1;
  height: 28px;
  border-radius: 7px;
  justify-content: center;
`;

const Bubble = styled.Text`
  color: ${(props) =>
    props.mode === 'active'
      ? props.theme.colors.pure.white
      : props.theme.colors.selectedGrey};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
`;
// font-family: "SF Pro Display";

const PressableBubble = styled.Pressable`
  width: 100%;
`;

const NotificationContainer = styled.View`
  position: absolute;
  top: -8px;
  right: 0;
`;

const NotificationImage = () => (
  <NotificationContainer>
    <Svg height="16" width="16">
      <Circle cx="8" cy="8" r="8" fill="#EB4E3D" />
    </Svg>
  </NotificationContainer>
);

const BarBubble = ({ isActive, hasNotification, onPress, children }) => {
  return (
    <BubbleContainer mode={isActive ? 'active' : 'inactive'}>
      <PressableBubble onPress={onPress}>
        <Bubble mode={isActive ? 'active' : 'inactive'}>{children}</Bubble>
        {hasNotification === true && <NotificationImage />}
      </PressableBubble>
    </BubbleContainer>
  );
};

export const BarSwitcher = ({ items, tab, onSelected }) => {
  const [current, setCurrent] = useState('');
  useLayoutEffect(() => {
    setCurrent(items[tab]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <Switcher>
        {items.map((item) => {
          return (
            <BarBubble
              key={item}
              isActive={item === current}
              onPress={() => {
                setCurrent(item);
                if (onSelected) {
                  onSelected(item);
                }
              }}
            >
              {item}
            </BarBubble>
          );
        })}
      </Switcher>
    </Container>
  );
};
