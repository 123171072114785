import React from 'react';
import { ActivityIndicator, Colors } from 'react-native-paper';
import styled from 'styled-components/native';

const LoadingActivityIndicator = styled(ActivityIndicator)`
  margin-left: -25px;
`;

const LoadingContainer = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;

export const Loading = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <LoadingContainer>
          <LoadingActivityIndicator
            size={50}
            animating={true}
            color={Colors.blue500}
          />
        </LoadingContainer>
      )}
    </>
  );
};
