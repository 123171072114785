export const treatments = [
  { key: 'RICER & warnings ', title: 'RICER & warnings ' },
  { key: 'Rest and monitor', title: 'Rest and monitor' },
  { key: 'Ice', title: 'Ice' },
  { key: 'Compression', title: 'Compression' },
  { key: 'Elevation', title: 'Elevation' },
  { key: 'Taping only', title: 'Taping only' },
  { key: 'Dressing', title: 'Dressing' },
  { key: 'Sling, splint, immobilised', title: 'Sling, splint, immobilised' },
  { key: 'Return with restriction', title: 'Return with restriction' },
  {
    key: 'Immediate return - unrestricted',
    title: 'Immediate return - unrestricted',
  },
  {
    key: 'Unable to return until clearance given',
    title: 'Unable to return until clearance given',
  },
  {
    key: 'Referred to GP, physio, dentist etc',
    title: 'Referred to GP, physio, dentist etc',
  },
  { key: 'Referred to hospital', title: 'Referred to hospital' },
  { key: 'Ambulance transport', title: 'Ambulance transport' },
];
