import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import {
  Background,
  BarHeaderSmall,
  ButtonBlue,
  CardInformation,
  Centered,
  FormFieldText,
  FormTableSelect,
} from '../../../components/ui/';

import { ClubRolesContext } from '../../../services/club-roles/club-roles.context';
import { ClubUsersContext } from '../../../services/club-users/club-users.context';

const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const ClubUserInviteScreen = ({ route, navigation }) => {
  const { clubUserTypes, onCreate } = useContext(ClubUsersContext);
  const { clubRoles, onRetrieve } = useContext(ClubRolesContext);

  const [club, setClub] = useState(null);
  const [email, setEmail] = useState('si@simpler.software');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedUserTypes, setSelectedUserTypes] = useState([]);

  const canSave = () => {
    return email !== '' && selectedUserTypes.length > 0;
  };

  useEffect(() => {
    if (route.params?.club) {
      setClub(route.params?.club);
      onRetrieve(route.params?.club);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params?.club]);

  return (
    <Background>
      <BarHeaderSmall
        title={'Invite a new user'}
        leftButtonTitle="Back"
        onLeftPress={() => {
          if (canSave()) {
          }
          navigation.goBack();
        }}
      />
      <Centered>
        {club?.id && (
          <CardInformationRow>
            <CardInformation title="Club">{club?.name}</CardInformation>
          </CardInformationRow>
        )}
        <FormFieldText
          label="Email"
          value={email}
          keyboardType={'email-address'}
          autoCapitalize={'none'}
          onChangeText={setEmail}
        />
        <FormTableSelect
          label={'Role'}
          values={clubRoles.map((clubRole) => ({
            key: clubRole.id,
            value: clubRole.name,
          }))}
          initialKeys={selectedRoles}
          multiselect={false}
          onSelected={(selected) => {
            setSelectedRoles(selected);
          }}
        />
        <FormTableSelect
          label={'Type'}
          values={clubUserTypes.map((clubUserType) => ({
            key: clubUserType,
            value: clubUserType,
          }))}
          initialKeys={selectedUserTypes}
          multiselect={false}
          onSelected={(selected) => {
            setSelectedUserTypes(selected);
          }}
        />
        <ButtonBlue
          label={'Invite'}
          isDisabled={!canSave()}
          onPress={() => {
            if (canSave) {
              const club_role_id =
                selectedRoles.length > 0 ? selectedRoles[0] : null;
              const clubUser = {
                email: email,
                club_role_id: club_role_id,
                club_user_type: selectedUserTypes[0],
              };
              onCreate({ club, clubUser });
              // navigation.goBack();
            }
          }}
        />
      </Centered>
    </Background>
  );
};
