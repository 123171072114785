import React from 'react';

import { Spacer } from '../../../components/spacer/spacer.component';
import { AccountContainer } from '../components/account.styles';
import { Button } from '../components/button.component';
import { Background } from '../components/background.component';

export const AccountScreen = ({ navigation }) => {
  return (
    <Background>
      <AccountContainer>
        <Button title={'Login'} onPress={() => navigation.navigate('Login')} />
        <Spacer size="large">
          <Button
            title={'Register'}
            onPress={() => navigation.navigate('Register')}
          />
        </Spacer>
      </AccountContainer>
    </Background>
  );
};
