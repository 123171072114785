import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  getClubs,
  createClub,
  updateClub,
  clubTransform,
  clubsTransform,
} from './clubs.service';
import { AuthenticationContext } from '../authentication/authentication.context';

export const ClubsContext = createContext();

export const ClubsContextProvider = ({ children }) => {
  const { user, token } = useContext(AuthenticationContext);

  const [clubs, setClubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onRetrieve = async () => {
    setIsLoading(true);
    setClubs([]);
    await getClubs({ token })
      .then(clubsTransform)
      .then((results) => {
        setIsLoading(false);
        setClubs(results);
        setError(null);
      })
      .catch((err) => {
        setIsLoading(false);
        setClubs([]);
        setError(err);
      });
  };

  const onCreate = (club) => {
    setIsLoading(true);
    createClub({ token, club })
      .then(clubTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          setClubs([response, ...clubs]);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onUpdate = (club) => {
    setIsLoading(true);
    updateClub({ token, club })
      .then(clubTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          const index = clubs.findIndex((record) => record.id === response.id);
          clubs[index] = response;
          setClubs(clubs);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  useEffect(() => {
    if (user) {
      onRetrieve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <ClubsContext.Provider
      value={{
        clubs,
        isLoading,
        error,
        onRetrieve,
        onCreate,
        onUpdate,
      }}
    >
      {children}
    </ClubsContext.Provider>
  );
};
