import React, { useState, useContext } from 'react';
import { ActivityIndicator, Colors } from 'react-native-paper';

import {
  AccountContainer,
  AuthInputBorder,
  AuthInput,
  AuthFields,
  ErrorContainer,
} from '../components/account.styles';
import { Button } from '../components/button.component';
import { Spacer } from '../../../components/spacer/spacer.component';
import { Text } from '../../../components/typography/text.component';
import { Background } from '../components/background.component';
import { Link } from '../components/link.component';

import { AuthenticationContext } from '../../../services/authentication/authentication.context';

export const RegisterScreen = ({ navigation }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { onRegister, isLoading, error } = useContext(AuthenticationContext);

  const letMeInEnabled = () => {
    return (
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      password === confirmPassword
    );
  };

  return (
    <Background>
      <AccountContainer>
        <Spacer size="heading" position="bottom">
          <Text variant="accountHeading">Activate Account</Text>
        </Spacer>
        <AuthFields>
          <AuthInputBorder>
            <AuthInput
              label="First Name"
              placeholder="First Name"
              value={firstName}
              // textContentType="emailAddress"
              // keyboardType="email-address"
              // autoCapitalize="none"
              onChangeText={(u) => setFirstName(u)}
            />
          </AuthInputBorder>
          <AuthInputBorder>
            <AuthInput
              label="Last Name"
              placeholder="Last Name"
              value={lastName}
              // textContentType="emailAddress"
              // keyboardType="email-address"
              // autoCapitalize="none"
              onChangeText={(u) => setLastName(u)}
            />
          </AuthInputBorder>
        </AuthFields>
        <AuthFields>
          <AuthInputBorder>
            <AuthInput
              label="Email"
              placeholder="Email"
              value={email}
              textContentType="emailAddress"
              keyboardType="email-address"
              autoCapitalize="none"
              onChangeText={(u) => setEmail(u)}
            />
          </AuthInputBorder>
          <AuthInputBorder>
            <AuthInput
              label="Password"
              placeholder="Password"
              value={password}
              textContentType="password"
              secureTextEntry
              autoCapitalize="none"
              onChangeText={(p) => setPassword(p)}
            />
          </AuthInputBorder>
          <AuthInput
            label="Confirm Password"
            placeholder="Confirm Password"
            value={confirmPassword}
            textContentType="password"
            secureTextEntry
            autoCapitalize="none"
            onChangeText={(p) => setConfirmPassword(p)}
          />
        </AuthFields>
        <Link onPress={() => navigation.navigate('Login')}>
          Already have an account? Log in
        </Link>
        <Spacer size="large">
          {!isLoading ? (
            <Button
              title={'Let Me In'}
              isDisabled={!letMeInEnabled()}
              onPress={() =>
                onRegister(
                  firstName,
                  lastName,
                  email,
                  password,
                  confirmPassword,
                )
              }
            />
          ) : (
            <ActivityIndicator animating={true} color={Colors.blue300} />
          )}
        </Spacer>
        <ErrorContainer size="large">
          <Text variant="error">{error}</Text>
        </ErrorContainer>
      </AccountContainer>
    </Background>
  );
};
