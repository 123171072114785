import camelize from 'camelize';
import { format } from 'date-fns';
import { host } from '../../utils/env';

const formattedDate = (isodate) => {
  const date = new Date(isodate);
  if (date.getFullYear() === new Date().getFullYear()) {
    return format(date, 'dd MMM');
  } else {
    return format(date, 'dd MMM yyyy');
  }
};

export const getReportNotes = async ({ token, report }) => {
  return fetch(
    `${host}/v1/clubs/${report.club.id}/reports/${report.id}/report_notes`,
    {
      headers: token,
    },
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const createReportNote = async ({ token, club_id, report_id, note }) => {
  return fetch(
    `${host}/v1/clubs/${club_id}/reports/${report_id}/report_notes`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...token,
      },
      body: JSON.stringify({
        report_note: {
          note: note,
        },
      }),
    },
  )
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const reportNoteTransform = (result = {}) => {
  result.createdAtShort = formattedDate(result.created_at);
  result.updatedAtShort = formattedDate(result.updated_at);
  return camelize(result);
};

export const reportNotesTransform = (results = []) => {
  return results.map((report) => reportNoteTransform(report));
};
