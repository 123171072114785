import React, { useContext } from 'react';
import styled from 'styled-components/native';
import { CardMessage } from '../../../components/ui/';
import { ReportsContext } from '../../../services/reports/reports.context';

const ReportsList = styled.FlatList``;
const AddButton = styled.Pressable``;
const RefreshControl = styled.RefreshControl``;

export const ReportListCompact = ({ navigation }) => {
  const { isLoading, reports, onRetrieve } = useContext(ReportsContext);

  return (
    <>
      <ReportsList
        data={reports}
        renderItem={({ item }) => (
          <AddButton
            onPress={() => {
              if (item.state === 'draft') {
                navigation.navigate('CreateReportScreen', { report: item });
              } else {
                navigation.navigate('ReportDetailScreen', { report: item });
              }
            }}
          >
            <CardMessage
              title={item.player}
              date={item.incidentAtShort}
              state={item.state}
            >
              {item.stateDescription}
            </CardMessage>
          </AddButton>
        )}
        keyExtractor={(item) => item.id}
        refreshControl={
          <RefreshControl
            refreshing={isLoading}
            onRefresh={() => {
              onRetrieve();
            }}
          />
        }
      />
    </>
  );
};
