import React, { createContext, useContext, useState } from 'react';
import {
  getClubRoles,
  createClubRole,
  updateClubRole,
  removeClubRole,
  clubRoleTransform,
  clubRolesTransform,
} from './club-roles.service';
import { AuthenticationContext } from '../authentication/authentication.context';

export const ClubRolesContext = createContext();

export const ClubRolesContextProvider = ({ children }) => {
  const { token } = useContext(AuthenticationContext);
  const [clubRoles, setClubRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onRetrieve = async (club) => {
    setIsLoading(true);
    setClubRoles([]);
    await getClubRoles({ token, club })
      .then(clubRolesTransform)
      .then((results) => {
        setIsLoading(false);
        setClubRoles(results);
        setError(null);
      })
      .catch((err) => {
        setIsLoading(false);
        setClubRoles([]);
        setError(err);
      });
  };

  const onCreate = (club, clubRole) => {
    setIsLoading(true);
    createClubRole({ token, club, clubRole })
      .then(clubRoleTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          setClubRoles([response, ...clubRoles]);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onUpdate = (club, clubRole) => {
    setIsLoading(true);
    updateClubRole({ token, club, clubRole })
      .then(clubRoleTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          const index = clubRoles.findIndex(
            (record) => record.id === response.id,
          );
          clubRoles[index] = response;
          setClubRoles(clubRoles);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  const onDelete = (club, clubRole) => {
    setIsLoading(true);
    removeClubRole({ token, club, clubRole })
      .then(clubRoleTransform)
      .then((response) => {
        if (response.status === 'error') {
          setIsLoading(false);
          setError(response.errors.full_messages.toString());
        } else {
          const filtered = clubRoles.filter((i) => i.id !== clubRole.id);
          setClubRoles(filtered);
          setIsLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.toString());
      });
  };

  return (
    <ClubRolesContext.Provider
      value={{
        clubRoles,
        isLoading,
        error,
        onRetrieve,
        onCreate,
        onUpdate,
        onDelete,
      }}
    >
      {children}
    </ClubRolesContext.Provider>
  );
};
