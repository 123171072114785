import React, { useContext, useState } from 'react';
import { FlatList, Keyboard, RefreshControl } from 'react-native';
import styled from 'styled-components/native';
import {
  ButtonBlue,
  CardNote,
  Centered,
  FormFieldNote,
  Loading,
} from '../../../components/ui/';

const NotesList = styled(FlatList).attrs({
  contentContainerStyle: {
    paddingTop: 0,
  },
})``;

import { ReportNotesContext } from '../../../services/report-notes/report-notes.context';

export const ReportNotes = () => {
  const { isLoading, reportNotes, onRetrieve, onCreate } =
    useContext(ReportNotesContext);
  const [note, setNote] = useState('');

  return (
    <>
      <Centered>
        <FormFieldNote label={'New note'} value={note} onChangeText={setNote} />
        <ButtonBlue
          label={'Add'}
          onPress={() => {
            if (note && note !== '') {
              onCreate(note);
              setNote('');
              Keyboard.dismiss();
            }
          }}
        />
      </Centered>
      <NotesList
        data={reportNotes}
        renderItem={({ item }) => (
          <Centered>
            <CardNote
              date={item.createdAtShort}
              type={item.mine ? 'me' : 'other'}
            >
              {item.note}
            </CardNote>
          </Centered>
        )}
        keyExtractor={(item) => item.id}
        refreshControl={
          <RefreshControl
            refreshing={isLoading}
            onRefresh={() => {
              onRetrieve();
            }}
          />
        }
      />
      <Loading isLoading={isLoading} />
    </>
  );
};
