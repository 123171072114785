import React, { useContext } from 'react';
import styled from 'styled-components/native';
import { CardMessage } from '../../../components/ui/';
import { ClubsContext } from '../../../services/clubs/clubs.context';

const ClubsList = styled.FlatList``;
const AddButton = styled.Pressable``;
const RefreshControl = styled.RefreshControl``;

export const ClubListCompact = ({ navigation }) => {
  const { isLoading, clubs, onRetrieve } = useContext(ClubsContext);

  return (
    <>
      <ClubsList
        data={clubs}
        renderItem={({ item }) => (
          <AddButton
            onPress={() => {
              navigation.navigate('ClubUpdateScreen', { club: item });
            }}
          >
            <CardMessage title={item.name} date={item.updatedAtShort}>
              {item.league}
            </CardMessage>
          </AddButton>
        )}
        keyExtractor={(item) => item.id}
        refreshControl={
          <RefreshControl
            refreshing={isLoading}
            onRefresh={() => {
              onRetrieve();
            }}
          />
        }
      />
    </>
  );
};
