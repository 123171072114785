import React from 'react';
import styled from 'styled-components/native';
import {
  ButtonBlue,
  CardInformation,
  Centered,
  FormFieldText,
} from '../../../components/ui/';

const Container = styled.View``;
const ScrollView = styled.ScrollView``;
const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const ClubDetails = ({ club, navigation }) => {
  return (
    <ScrollView>
      <Centered>
        <Container>
          <CardInformationRow>
            <CardInformation title="Created">
              {club?.createdAtShort}
            </CardInformation>
            <CardInformation title="Last Updated">
              {club?.updatedAtShort}
            </CardInformation>
          </CardInformationRow>
          <FormFieldText label="Name" value={club.name} />
          <FormFieldText label="League" value={club.league} />
          <ButtonBlue
            label="Edit"
            onPress={() => {
              navigation.navigate('ClubUpdateScreen', { club });
            }}
          />
        </Container>
      </Centered>
    </ScrollView>
  );
};
