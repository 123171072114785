import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'react-native';
import styled from 'styled-components/native';
import {
  Background,
  BarHeaderLarge,
  Loading,
  VerticalScroll,
} from '../../../components/ui';
import {
  sizeClasses,
  SizeClassView,
} from '../../../components/utility/size-class-view.component';
import { DataTable } from 'react-native-paper';

import PlusPNG from '../../../../assets/icons/add-full_blue.png';
const PlusIcon = styled.Image.attrs({
  source: PlusPNG,
})`
  width: 24px;
  height: 24px;
`;

const Container = styled.View`
  flex: 1;
`;
const RefreshTest = styled.Text`
  color: ${(props) => props.theme.colors.simple.blue};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;
const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 4px;
`;
const DataTableView = styled(DataTable)`
  background-color: white;
  flex: 1;
`;
const optionsPerPage = [2, 3, 4];

import { ChangeClub } from '../../clubs/components/change-club.component';
import { ChangeTeam } from '../../teams/components/change-team.component';
// import { ReportListRegular } from "../components/report-list-regular.component";
// import { ReportListCompact } from "../components/report-list-compact.component";

import { PlayersContext } from '../../../services/players/players.context';
import { ProfileContext } from '../../../services/profile/profile.context';

export const PlayersScreen = ({ navigation }) => {
  const { players, onRetrieve, error, isLoading } = useContext(PlayersContext);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(optionsPerPage[0]);
  const { currentClub, currentTeam, setCurrentTeam } =
    useContext(ProfileContext);

  useEffect(() => {
    if (error) {
      Alert.alert('Something went wrong', error, [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [error]);

  useEffect(() => {
    if (currentClub) {
      setTimeout(() => {
        onRetrieve(currentTeam?.name);
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClub, currentTeam]);

  return (
    <Background>
      <BarHeaderLarge title="Players">
        <SizeClassView size={sizeClasses.regular}>
          <BarButton
            onPress={() => {
              onRetrieve();
            }}
          >
            <RefreshTest>Refresh</RefreshTest>
          </BarButton>
        </SizeClassView>
      </BarHeaderLarge>
      <ChangeTeam navigation={navigation} />
      <Loading isLoading={isLoading} />
      <Container>
        <DataTableView>
          <DataTable.Header>
            <DataTable.Title>First Name</DataTable.Title>
            <DataTable.Title>Last Name</DataTable.Title>
            <DataTable.Title>Medical Conditions</DataTable.Title>
            <SizeClassView size={sizeClasses.regular}>
              <DataTable.Title>Team</DataTable.Title>
            </SizeClassView>
          </DataTable.Header>
          <VerticalScroll>
            {players.map((player) => (
              <DataTable.Row
                key={player.id}
                onPress={() => {
                  navigation.navigate('PlayerDetailScreen', { player });
                }}
              >
                <DataTable.Cell>{player.firstName}</DataTable.Cell>
                <DataTable.Cell>{player.lastName}</DataTable.Cell>
                <DataTable.Cell>{player.medicalNotes}</DataTable.Cell>
                <SizeClassView size={sizeClasses.regular}>
                  <DataTable.Cell>
                    {player.clubTeams.map((team) => team.name).join(', ')}
                  </DataTable.Cell>
                </SizeClassView>
              </DataTable.Row>
            ))}
          </VerticalScroll>
        </DataTableView>
      </Container>
    </Background>
  );
};
