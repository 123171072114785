import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import {
  Background,
  BarHeaderSmall,
  ButtonBlue,
  CardInformation,
  Centered,
  FormFieldText,
  VerticalScroll,
} from '../../../components/ui/';
import { ClubsContext } from '../../../services/clubs/clubs.context';

const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const ClubUpdateScreen = ({ route, navigation }) => {
  const { onCreate, onUpdate } = useContext(ClubsContext);

  const [club, setClub] = useState(null);
  const [name, setName] = useState('');

  const canSave = () => {
    return name !== '';
  };

  useEffect(() => {
    if (route.params?.club) {
      setClub(route.params?.club);
      setName(route.params?.club.name);
    }
  }, [route.params?.club]);

  return (
    <Background>
      <BarHeaderSmall
        title={club?.id ? 'Update Club' : 'New Club'}
        leftButtonTitle="Back"
        onLeftPress={() => {
          if (canSave()) {
          }
          navigation.goBack();
        }}
      />
      <VerticalScroll>
        <Centered>
          {club?.id && (
            <CardInformationRow>
              <CardInformation title="Created">
                {club?.createdAtShort}
              </CardInformation>
              <CardInformation title="Last Updated">
                {club?.updatedAtShort}
              </CardInformation>
            </CardInformationRow>
          )}
          <FormFieldText
            label="Club name"
            value={name}
            onChangeText={setName}
          />
          <ButtonBlue
            label={club?.id ? 'Update' : 'Create'}
            isDisabled={!canSave()}
            onPress={() => {
              if (club?.id) {
                onUpdate({ id: club.id, name });
              } else {
                onCreate({ name });
              }
              navigation.goBack();
            }}
          />
        </Centered>
      </VerticalScroll>
    </Background>
  );
};
