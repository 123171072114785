import { adviceGiven } from './advice-given';
import { injuries } from './injuries';
import { injuryCauses } from './injury-causes';
import { oppositionPlayers } from './opposition-players';
import reports from './reports.json';
import { suspectedConcussion } from './suspected-concussion';
import { treatments } from './treatments';
import { treatmentAssisted } from './treatment-assisted';
import { drabcdActionPlan } from './drabcd-action-plan';

export const mocks = {
  adviceGiven,
  drabcdActionPlan,
  injuries,
  injuryCauses,
  oppositionPlayers,
  reports,
  suspectedConcussion,
  treatments,
  treatmentAssisted,
};
