export const injuries = [
  { key: 'Broken arm', title: 'Broken arm' },
  { key: 'Broken leg', title: 'Broken leg' },
  { key: 'Broken wrist', title: 'Broken wrist' },
  { key: 'Broken finger(s)', title: 'Broken finger(s)' },
  { key: 'Broken collarbone', title: 'Broken collarbone' },
  { key: 'ACL', title: 'ACL' },
  { key: 'Ankle sprain', title: 'Ankle sprain' },
  { key: 'Knee sprain', title: 'Knee sprain' },
  { key: 'Shoulder sprain', title: 'Shoulder sprain' },
  { key: 'Hamstring', title: 'Hamstring' },
  { key: 'Bruising', title: 'Bruising' },
  { key: 'Graze, abrasion', title: 'Graze, abrasion' },
  { key: 'Dislocation', title: 'Dislocation' },
];
