import React from 'react';
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import { ClubsScreen } from '../../features/clubs/screens/clubs.screen';
import { ClubUpdateScreen } from '../../features/clubs/screens/club-update.screen';
import { ClubDetailScreen } from '../../features/clubs/screens/club-detail.screen';
import { ClubUserInviteScreen } from '../../features/clubs/screens/club-user-invite.screen';
import { ClubPlayerImportScreen } from '../../features/clubs/screens/club-player-import-screen';

import { AppModulesContextProvider } from '../../services/app-modules/app-modules.context';
import { ClubsContextProvider } from '../../services/clubs/clubs.context';
import { ClubUsersContextProvider } from '../../services/club-users/club-users.context';
import { ClubPlayersContextProvider } from '../../services/club-players/club-players.context';
import { PlayersContextProvider } from '../../services/players/players.context';
import { ClubPlayerListsContextProvider } from '../../services/club-player-lists/club-player-lists.context';
import { ClubRolesContextProvider } from '../../services/club-roles/club-roles.context';

const ClubsStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const ClubsNavigator = () => {
  return (
    <AppModulesContextProvider>
      <ClubsContextProvider>
        <ClubRolesContextProvider>
          <ClubUsersContextProvider>
            <ClubPlayersContextProvider>
              <ClubPlayerListsContextProvider>
                <ClubsStack.Navigator screenOptions={createScreenOptions}>
                  <ClubsStack.Screen
                    name="ClubsScreen"
                    component={ClubsScreen}
                  />
                  <ClubsStack.Screen
                    name="ClubUpdateScreen"
                    component={ClubUpdateScreen}
                  />
                  <ClubsStack.Screen
                    name="ClubDetailScreen"
                    component={ClubDetailScreen}
                  />
                  <ClubsStack.Screen
                    name="ClubUserInviteScreen"
                    component={ClubUserInviteScreen}
                  />
                  <ClubsStack.Screen
                    name="ClubPlayerImportScreen"
                    component={ClubPlayerImportScreen}
                  />
                </ClubsStack.Navigator>
              </ClubPlayerListsContextProvider>
            </ClubPlayersContextProvider>
          </ClubUsersContextProvider>
        </ClubRolesContextProvider>
      </ClubsContextProvider>
    </AppModulesContextProvider>
  );
};
