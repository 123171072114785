import { Platform } from 'react-native';

const liveHost = 'https://api.simpler.software';

// MacBook Air
// const localHost = 'http://192.168.2.43:4000'; // ha-fi
const localHost = 'http://192.168.20.21:4000'; // Jo's place
// const localHost = 'http://192.168.1.4:4000'; // si-fi
// const localHost = 'http://192.168.1.129:4000'; // star-fi

// Mac Mini
// const localHost = "http://192.168.1.6:4000"; // si-fi
// const localHost = "http://192.168.1.152:4000"; // star-fi

export const isAndroid = Platform.OS === 'android';
export const isDevelopment = process.env.NODE_ENV === 'development';
export const isMock = true;
export const host = !isDevelopment ? liveHost : localHost;
// console.log(host);
