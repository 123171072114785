import React from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

const Container = styled.View`
  padding: 16px;
  height: 320px;
  width: 100%;
`;

const Background = styled.View`
  flex: 1;
  border-radius: 4px;
  border-width: 1px;
  border-color: 'rgba(0,0,0,0.2)';
  background-color: white;
`;

const InnerContainer = styled.View`
  padding: 32px;
`;

const DateText = styled.Text`
  color: ${(props) => props.theme.colors.simple.blue};
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 12px;
`;
// font-family: "SF Pro Display";

const Title = styled.Text.attrs({
  numberOfLines: 2,
  ellipsizeMode: 'tail',
})`
  margin-top: 8px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  height: 40px;
  width: 100%;
`;
// font-family: "SF Pro Display";

const Subheading = styled.Text`
  height: 12px;
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
`;
// font-family: "SF Pro Display";

const Text = styled.Text`
  height: 26px;
  width: 95%;
  color: #728596;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
`;
// font-family: "SF Pro Display";

const CardRow = styled.View`
  margin-top: 8px;
  flex-direction: row;
  width: 100%;
  height: 32px;
`;

const CardDetail = styled.View`
  width: 50%;
`;

const ImageContainer = styled.View`
  width: 100%;
  height: 124px;
  margin-top: 8px;
  background-color: #cfd1e1;
`;

const Image = styled.Image.attrs({
  source: require('../../../assets/placeholder-file-preview.png'),
})`
  width: 100%;
  height: 100%
  border-radius: 4px;
  resize-mode: contain;
`;

export const CardLargeText = ({
  date = '28/06',
  title = 'File description something something',
  data = [
    { title: 'File format', value: 'PDF' },
    { title: 'Uploaded by', value: 'Simon' },
  ],
  onPress,
}) => {
  return (
    <Container>
      <Background>
        <InnerContainer>
          <DateText>{date}</DateText>
          <Title>{title}</Title>
          <CardRow>
            <CardDetail>
              <Subheading>{data[0].title}</Subheading>
              <Text>{data[0].value}</Text>
            </CardDetail>
            <CardDetail>
              <Subheading>{data[1].title}</Subheading>
              <Text>{data[1].value}</Text>
            </CardDetail>
          </CardRow>
          <Pressable onPress={onPress}>
            <ImageContainer>
              <Image />
            </ImageContainer>
          </Pressable>
        </InnerContainer>
      </Background>
    </Container>
  );
};
