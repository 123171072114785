import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'react-native';
import styled from 'styled-components/native';
import {
  AvoidingView,
  Background,
  BarHeaderSmall,
  ButtonBlue,
  Centered,
  FormFieldTextDate,
  FormFieldSelect,
  FormFieldText,
  FormFieldTextMulti,
  FormFieldToggle,
  ButtonWhite,
  VerticalScroll,
} from '../../../components/ui/';
import { mocks } from '../../../services/reports/mock';
import { Spacer } from '../../../components/spacer/spacer.component';

import { ReportsContext } from '../../../services/reports/reports.context';
import { PlayersContext } from '../../../services/players/players.context';
import { TeamsContext } from '../../../services/teams/teams.context';

export const CreateReportScreen = ({ navigation, route }) => {
  const { onCreate, onUpdate } = useContext(ReportsContext);
  const { players, onRetrieve: retrievePlayers } = useContext(PlayersContext);
  const { teams, onRetrieve: retrieveTeams } = useContext(TeamsContext);

  const [id, setId] = useState(null);
  const [state, setState] = useState(null);
  const [team, setTeam] = useState([]);
  const [player, setPlayer] = useState({ id: null, name: '' });
  const [incidentAt, setIncidentAt] = useState(new Date());
  const [concussion, setConcussion] = useState(false);
  const [drabcd, setDrabcd] = useState(false);
  const [injury, setInjury] = useState([]);
  const [injuryCause, setInjuryCause] = useState([]);
  const [treatment, setTreatment] = useState([]);
  const [adviceGiven, setAdviceGiven] = useState([]);
  const [treatmentAssisted, setTreatmentAssisted] = useState(false);
  const [oppositionTeam, setOppositionTeam] = useState([]);
  const [venue, setVenue] = useState('');
  const [comment, setComment] = useState('');

  const reportData = () => {
    return {
      player: player.name,
      club_player_id: player.id,
      incident_at: incidentAt,
      drabcd: drabcd,
      concussion: concussion,
      injury: injury.join('|||'),
      injuryCause: injuryCause.join('|||'),
      treatment: treatment.join('|||'),
      adviceGiven: adviceGiven.join('|||'),
      treatmentAssisted: treatmentAssisted ? 'Yes' : 'No',
      oppositionTeam: oppositionTeam.join('|||'),
      venue,
      comment,
    };
  };

  const [currentPicker, setCurrentPicker] = useState(null);
  useEffect(() => {
    if (route.params?.items) {
      switch (currentPicker) {
        case 'Team':
          setTeam(route.params?.items);
          break;
        case 'Player':
          setPlayer(route.params?.items);
          break;
        case 'Injury':
          setInjury(route.params?.items);
          break;
        case 'InjuryCause':
          setInjuryCause(route.params?.items);
          break;
        case 'Treatment':
          setTreatment(route.params?.items);
          break;
        case 'AdviceGiven':
          setAdviceGiven(route.params?.items);
          break;
        case 'OppositionTeam':
          setOppositionTeam(route.params?.items);
          break;
      }
      setCurrentPicker(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params?.items]);

  const canSave = () => {
    return player.name.length > 0;
  };

  const save = (state) => {
    if (id) {
      onUpdate({
        id,
        state: state,
        ...reportData(),
      });
    } else {
      onCreate({
        state: state,
        ...reportData(),
      });
    }
  };

  useEffect(() => {
    if (route.params?.report) {
      const { report } = route.params;
      setId(report.id);
      setState(report.state);
      setIncidentAt(report.incidentAt);
      setPlayer({ id: null, name: report.player });
      setDrabcd(report.drabcd);
      setConcussion(report.concussion);
      setInjury(report.injury.split('|||').filter(Boolean));
      setInjuryCause(report.injuryCause.split('|||').filter(Boolean));
      setTreatment(report.treatment.split('|||').filter(Boolean));
      setAdviceGiven(report.adviceGiven.split('|||').filter(Boolean));
      setTreatmentAssisted(report.treatmentAssisted === 'Yes');
      setOppositionTeam(report.oppositionTeam.split('|||').filter(Boolean));
      setVenue(report.venue);
      setComment(report.comment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params?.report]);

  useEffect(() => {
    retrievePlayers(team.join(','));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  return (
    <Background>
      <BarHeaderSmall
        title={id ? 'Update Medical Report' : 'Create Medical Report'}
        leftButtonTitle="Cancel"
        onLeftPress={() => {
          if (canSave()) {
            if (state !== 'submitted') {
              Alert.alert(
                'Save to draft?',
                'There are unsaved changes make to this report.',
                [
                  {
                    text: 'No',
                    onPress: () => navigation.goBack(),
                    style: 'cancel',
                  },
                  {
                    text: 'Yes',
                    onPress: () => {
                      save('draft');
                      navigation.goBack();
                    },
                  },
                ],
              );
            } else {
              navigation.goBack();
            }
          } else {
            navigation.goBack();
          }
        }}
      />
      <AvoidingView>
        <VerticalScroll>
          <Centered>
            <FormFieldSelect
              label="Player"
              header="Player Name"
              required={true}
              values={[player.name.length > 0 ? player.name : 'Select']}
              onSelected={() => {
                setCurrentPicker('Player');
                navigation.navigate('PlayerSelection', {
                  title: 'Player',
                  header: 'PICK ONE OR ADD ANOTHER',
                  items: players
                    .map((record) => record.firstName + ' ' + record.lastName)
                    .sort(),
                  selectedItems: player,
                });
              }}
            />
            <FormFieldTextDate
              label="Incident Date"
              required={true}
              value={incidentAt}
              onUpdated={(value) => {
                setIncidentAt(value);
              }}
            />
            <FormFieldToggle
              label="DRABCD"
              header="DANGER, RESPONSE, SEND FOR HELP, AIRWAY, BREATHING, COMPRESSION, DEFIBRILLATION"
              initialValue={drabcd}
              onSelected={setDrabcd}
            />
            <FormFieldToggle
              label="Suspected concussion"
              initialValue={concussion}
              onSelected={setConcussion}
            />
            <FormFieldSelect
              label="Injury"
              values={injury.length > 0 ? injury : ['Select']}
              onSelected={() => {
                setCurrentPicker('Injury');
                navigation.navigate('ListSelection', {
                  title: 'Injury',
                  header: 'PICK ONE OR MORE, OR ADD ANOTHER',
                  items: mocks.injuries.map((data) => data.title),
                  multiselect: true,
                  selectedItems: injury,
                });
              }}
            />
            <FormFieldSelect
              label="Cause of injury"
              values={injuryCause.length > 0 ? injuryCause : ['Select']}
              onSelected={() => {
                setCurrentPicker('InjuryCause');
                navigation.navigate('ListSelection', {
                  title: 'Cause of injury',
                  header: 'PICK ONE OR MORE, OR ADD ANOTHER',
                  items: mocks.injuryCauses.map((data) => data.title),
                  multiselect: true,
                  selectedItems: injuryCause,
                });
              }}
            />
            <FormFieldSelect
              label="Treatment"
              values={treatment.length > 0 ? treatment : ['Select']}
              onSelected={() => {
                setCurrentPicker('Treatment');
                navigation.navigate('ListSelection', {
                  title: 'Treatment',
                  header: 'PICK ONE OR MORE, OR ADD ANOTHER',
                  description:
                    'RICER: Rest, Ice, Compression, Elevation, Referral',
                  items: mocks.treatments.map((data) => data.title),
                  multiselect: true,
                  selectedItems: treatment,
                });
              }}
            />
            <FormFieldToggle
              label="Treated by St John's or Colbrow?"
              initialValue={treatmentAssisted}
              onSelected={setTreatmentAssisted}
            />
            <FormFieldSelect
              label="Opposition club"
              values={oppositionTeam.length > 0 ? oppositionTeam : ['Select']}
              onSelected={() => {
                setCurrentPicker('OppositionTeam');
                navigation.navigate('ListSelection', {
                  title: 'Opposition',
                  header: 'PICK ONE OR ADD ANOTHER',
                  items: mocks.oppositionPlayers.map((data) => data.title),
                  multiselect: false,
                  selectedItems: oppositionTeam,
                });
              }}
            />
            <FormFieldText
              label="Venue"
              values={venue}
              onChangeText={setVenue}
            />
            <FormFieldTextMulti
              label="Additional comments"
              value={comment}
              onChangeText={setComment}
            />
            <ButtonBlue
              label="Submit"
              isDisabled={!canSave()}
              onPress={() => {
                save('submitted');
                navigation.goBack();
              }}
            />
            {state !== 'submitted' && (
              <ButtonWhite
                label="Save to draft"
                isDisabled={!canSave()}
                onPress={() => {
                  save('draft');
                  navigation.goBack();
                }}
              />
            )}
            <Spacer type="bottom" size="large" />
          </Centered>
        </VerticalScroll>
      </AvoidingView>
    </Background>
  );
};
