import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { AccountScreen } from '../../features/account/screens/account.screen';
import { LoginScreen } from '../../features/account/screens/login.screen';
import { RegisterScreen } from '../../features/account/screens/register.screen';
import { ForgotPasswordScreen } from '../../features/account/screens/forgot-password.screen';
import { CreatePasswordScreen } from '../../features/account/screens/create-password.screen';

const Stack = createStackNavigator();

const createScreenOptions = () => {
  return {
    // presentation: "modal",
    headerShown: false,
  };
};

export const AccountNavigator = () => {
  return (
    <Stack.Navigator screenOptions={createScreenOptions}>
      <Stack.Screen name="Login" component={LoginScreen} />
      <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />
      <Stack.Screen name="CreatePassword" component={CreatePasswordScreen} />
      <Stack.Screen name="Activate" component={RegisterScreen} />
      <Stack.Screen name="Main" component={AccountScreen} />
    </Stack.Navigator>
  );
};
