import React from 'react';
import styled, { css } from 'styled-components/native';

const Container = styled.View`
  padding-horizontal: 16px;
  padding-vertical: 8px;
`;

const Background = styled.View`
  width: 100%;
  ${(props) =>
    props.type !== 'me' &&
    css`
      border-width: 1px;
      border-color: ${(p) => p.theme.colors.border};
    `}
  border-radius: 4px;
  background-color: ${(props) =>
    props.type === 'me'
      ? props.theme.colors.offWhite
      : props.theme.colors.pure.white};
  padding: 32.5px;
`;

const Date = styled.Text`
  width: 100%;
  color: #1242d2;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  margin-bottom: 8px;
`;
// font-family: "SF Pro Display";

const Text = styled.Text`
  width: 100%;
  color: #000000;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

export const CardNote = ({ date, type, children }) => {
  return (
    <Container>
      <Background type={type}>
        <Date>{date}</Date>
        <Text>{children}</Text>
      </Background>
    </Container>
  );
};
