import React from 'react';
import styled from 'styled-components/native';

const Container = styled.View``;

const Background = styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
  padding-vertical: 8px;
`;
import LocationPNG from '../../../assets/icons/location-full_blue.png';
const LocationIcon = styled.Image.attrs({
  source: LocationPNG,
})``;

const Icon = styled(LocationIcon)`
  width: 16px;
  height: 16px;
`;

const IconContainer = styled.View`
  padding-left: 16px;
  padding-right: 8px;
`;

const Name = styled.Text`
  flex: 1;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

const Link = styled.Text`
  color: #1242d2;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  text-transform: uppercase;
  padding-horizontal: 16px;
`;
// font-family: "SF Pro Display";

const PressableLink = styled.Pressable``;

export const BarChanger = ({ title, link, hasIcon, onPress }) => {
  return (
    <Container>
      <Background>
        <IconContainer>{!!hasIcon && <Icon />}</IconContainer>
        <Name>{title}</Name>
        <PressableLink onPress={onPress}>
          <Link>{link}</Link>
        </PressableLink>
      </Background>
    </Container>
  );
};
