import React from 'react';
import styled from 'styled-components/native';

const Container = styled.View`
  width: 100%;
  margin-vertical: 8px;
`;

const LabelText = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;
// font-family: "SF Pro Display";

const InputContainer = styled.View`
  min-height: 96px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
  padding-horizontal: 16px;
  padding-bottom: 7px;
`;

const Input = styled.TextInput.attrs({
  multiline: true,
  numberOfLines: 0,
  textAlignVertical: 'top',
})`
  flex: 1;
  width: 100%;
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  margin-vertical: 8px;
`;
// font-family: "SF Pro Display";

export const FormFieldNote = ({ label, value, placeholder, onChangeText }) => {
  return (
    <Container>
      <LabelText>{label}</LabelText>
      <InputContainer>
        <Input
          value={value}
          placeholder={placeholder}
          onChangeText={onChangeText}
        />
      </InputContainer>
    </Container>
  );
};
