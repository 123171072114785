import React, { createContext, useContext, useEffect, useState } from 'react';
import { getTeams, teamsTransform } from './teams.service';
import { AuthenticationContext } from '../authentication/authentication.context';
import { ProfileContext } from '../profile/profile.context';

export const TeamsContext = createContext();

export const TeamsContextProvider = ({ children }) => {
  const { currentClub } = useContext(ProfileContext);
  const { token } = useContext(AuthenticationContext);
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onRetrieve = async () => {
    if (currentClub) {
      setIsLoading(true);
      setTeams([]);
      await getTeams({ token, club: currentClub })
        .then(teamsTransform)
        .then((results) => {
          setIsLoading(false);
          setTeams(results);
          setError(null);
        })
        .catch((err) => {
          setIsLoading(false);
          setTeams([]);
          setError(err);
        });
    }
  };

  return (
    <TeamsContext.Provider
      value={{
        teams,
        isLoading,
        error,
        onRetrieve,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};
